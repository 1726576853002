import { isEmpty } from 'lodash'

import styles from './TelephoneNumber.module.scss'

const TelephoneNumber = ({ phoneNumber = '' }) => {
  const phoneWithoutTty = phoneNumber.split('TTY')[0].trim()

  if (isEmpty(phoneWithoutTty)) {
    return null
  }

  return (
    <a className={styles.phoneLink} href={`tel:${phoneWithoutTty}`}>
      {phoneNumber}
    </a>
  )
}

export default TelephoneNumber
