import { List, ListItem, ListItemText } from '@mui/material'

import PageLayout from '../Layouts/PageLayout/PageLayout'
import TelephoneNumber from '../TelephoneNumber/TelephoneNumber'

import styles from './ContactUs.module.scss'

const ContactUs = () => {
  return (
    <PageLayout>
      <h1 className={styles.header}>Contact Us</h1>
      <div className={styles.contactTab}>
        <p className={styles.contactTabElement}>Medicare</p>
        <p className={styles.contactTabElementLast} />
      </div>
      <div className={styles.card}>
        <h2 className={styles.subheader}>Speak to a Human</h2>
        <List>
          <ListItem>
            <ListItemText
              primary="Medicare Customer Service"
              secondary={
                <>
                  <TelephoneNumber phoneNumber="1-833-377-1105 TTY 711" />
                  <br />
                  9am - 6pm EST
                  <br />
                  Monday - Sunday
                  <br />
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Medicare Sales"
              secondary={
                <>
                  <TelephoneNumber phoneNumber="1-833-906-4445 TTY 711" />
                  <br />
                  9am - 6pm EST
                  <br />
                  Monday - Sunday
                  <br />
                </>
              }
            />
          </ListItem>
        </List>
      </div>

      <div className={styles.card}>
        <h2 className={styles.subheader}>Send us a message</h2>
        <List>
          <ListItem>
            <p>
              Use this form to reach out to our customer service team. We'll get back to you in 1 to
              2 business days.
            </p>
          </ListItem>
        </List>
        <iframe
          className={styles.iframe}
          src="https://assuranceiq.formstack.com/forms/mp_medicare"
          title="contact us"
          scrolling="no"
        />
      </div>
    </PageLayout>
  )
}

export default ContactUs
