import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Grid, MenuItem, Select } from '@mui/material'

import UpdateButton from './UpdateButton'
import { put } from '../../utils/requester'

const EditGender = ({ defaultValue, personId }) => {
  const [gender, setGender] = useState(defaultValue || '')
  const navigate = useNavigate()

  const updateGender = () => {
    const body = { gender: gender }
    return put(`/member_portal/api/v1/people/${personId}`, body).then(() => {
      navigate('/profile')
    })
  }

  return (
    <Grid container direction={'column'} spacing={4} justify="center">
      <Grid item>
        <Select
          id="gender"
          value={gender}
          defaultValue={gender}
          label="Gender"
          variant="outlined"
          onChange={(e) => setGender(e.target.value)}
          fullWidth
        >
          <MenuItem hidden value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </Grid>
      <UpdateButton handler={updateGender} />
    </Grid>
  )
}

export default EditGender
