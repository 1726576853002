import { useEffect, useState } from 'react'

import Loader from 'shared/components/Loader'
import { isEmpty } from 'lodash'

import MedicareCompletedApplication from '../MedicareCompletedApplication/MedicareCompletedApplication'
import MedicarePlanModal from '../MedicarePlanModal/MedicarePlanModal'
import MedicareProgressApplication from '../MedicareProgressApplication/MedicareProgressApplication'
import PlanModal from '../PlanModal/PlanModal'
import { getJson } from '../../utils/requester'

const MedicareApplication = ({ application, medicareData }) => {
  const [reloadApplication, setReloadApplication] = useState(true)
  const [progressDetail, setProgressDetail] = useState({})
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
      setReloadApplication(true)
    }
  }

  useEffect(() => {
    if (reloadApplication === false) {
      return
    }
    getJson(
      `/member_portal/api/v1/leads/${application.attributes.lead_id}/applications/medicare/progress`,
    ).then((result) => {
      setProgressDetail(result.data.attributes)
      setReloadApplication(false)
    })
  }, [application, reloadApplication])

  const coverage_progress =
    (progressDetail.progress_status &&
      progressDetail.progress_status.find((obj) => obj.status_name === 'coverage')) ||
    {}

  if (reloadApplication === true) {
    return <Loader />
  }

  if (isEmpty(application) || isEmpty(coverage_progress)) {
    return null
  }

  if (coverage_progress.progress === 100) {
    return (
      <MedicareCompletedApplication application={application} progressDetail={progressDetail} />
    )
  }

  return (
    <>
      <MedicareProgressApplication
        application={application}
        progressDetail={progressDetail}
        medicareData={medicareData}
        handleOpen={handleOpen}
      />
      <PlanModal headerTitle="Let’s find your plan!" handleClose={handleClose} open={open}>
        <MedicarePlanModal application={application} />
      </PlanModal>
    </>
  )
}

export default MedicareApplication
