import AccountProfileIcon from 'images/member_portal/account_profile.svg'
import ApplicationIcon from 'images/member_portal/application.svg'
import CarrierCardIcon from 'images/member_portal/carrier_card.svg'
import CostsIcon from 'images/member_portal/costs.svg'
import MedicareIcon from 'images/member_portal/medicare.svg'
import PhysicianRxIcon from 'images/member_portal/physician_rx.svg'

import BannerPageLayout from '../Layouts/BannerPageLayout/BannerPageLayout'
import HelpCenterCard from './HelpCenterCard/HelpCenterCard'
import NeedHelp from './NeedHelp'

import styles from './HelpCenter.module.scss'

const HelpCenter = () => {
  return (
    <>
      <BannerPageLayout banner="Help Center">
        <h1 className={styles.header}>Topics</h1>
        <div className={styles.cardsRowWrapper}>
          <HelpCenterCard
            src={MedicareIcon}
            altText="medicare"
            header="Medicare"
            text="Learn about different Medicare options"
            faqItem="medicare"
          />
          <HelpCenterCard
            src={ApplicationIcon}
            altText="applications"
            header="Applications"
            text="Find out what happens after you apply and what to expect"
            faqItem="applications"
          />
          <HelpCenterCard
            src={AccountProfileIcon}
            altText="account profile"
            header="Account Profile"
            text="Curious how we use the data you provide us? Learn about it here"
            faqItem="accountProfile"
          />
          <HelpCenterCard
            src={CarrierCardIcon}
            altText="carrier cards"
            header="Carrier Cards"
            text="When should you expect carrier issued insurance card?"
            faqItem="carrierCards"
          />
          <HelpCenterCard
            src={CostsIcon}
            altText="costs"
            header="Costs"
            text="Copay & coinsurance have you confused? We can help explain"
            faqItem="costs"
          />
          <HelpCenterCard
            src={PhysicianRxIcon}
            altText="physicians and prescriptions"
            header="Physicians & Rx"
            text="Prescription & physician coverage may change under your new plan"
            faqItem="physicians"
          />
        </div>
        <NeedHelp />
      </BannerPageLayout>
    </>
  )
}

export default HelpCenter
