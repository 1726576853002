import { NavLink } from 'react-router-dom'

import styles from './SidebarTopNavs.module.scss'

const SidebarTopNavs = ({ navItems, onClick }) => {
  return (
    <ul className={styles.root}>
      {navItems.map((row) => (
        <li key={row.name}>
          <NavLink
            onClick={onClick}
            to={row.route}
            className={({ isActive }) =>
              isActive ? styles.sideNavHeaderHighlighted : styles.sideNavChildren
            }
          >
            {row.name}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default SidebarTopNavs
