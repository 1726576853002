import AutoIcon from 'images/member_portal/auto.svg'
import HealthIcon from 'images/member_portal/health.svg'
import HomeIcon from 'images/member_portal/home.svg'
import LifeIcon from 'images/member_portal/life.svg'
import MedicareIcon from 'images/member_portal/medicare.svg'

export const icons = {
  medicare: MedicareIcon,
  life: LifeIcon,
  home: HomeIcon,
  auto: AutoIcon,
  health: HealthIcon,
  default: LifeIcon,
}

export const getIcon = (lead) => {
  return icons[lead.lead_type] || icons['default']
}

export const getIconByLeadType = (lead_type) => {
  return icons[lead_type] || null
}
