import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Grid, TextField } from '@mui/material'

import UpdateButton from './UpdateButton'
import { put } from '../../utils/requester'

const EditBirthDate = ({ defaultValue, personId }) => {
  const [birthDate, setBirthDate] = useState(defaultValue)
  const navigate = useNavigate()

  const updateBirthDate = () => {
    const body = { birth_date: birthDate }
    return put(`/member_portal/api/v1/people/${personId}`, body).then(() => {
      navigate('/profile')
    })
  }

  return (
    <Grid container direction={'column'} spacing={4} justify="center">
      <Grid item>
        <TextField
          id="birth-date"
          label="Date of Birth"
          type="date"
          defaultValue={birthDate}
          variant="outlined"
          onChange={(e) => setBirthDate(e.target.value)}
          fullWidth
        />
      </Grid>
      <UpdateButton handler={updateBirthDate} />
    </Grid>
  )
}

export default EditBirthDate
