import Grid from '@mui/material/Grid'

import MedicareApplications from '../MedicareApplications/MedicareApplications'
import { capitalizeFirstLetter } from '../../utils'
import { getIconByLeadType } from '../../utils/icons'

import applicationStyles from './MemberApplications.module.scss'

const getApplicationsForLoi = (lead_type, applications) => {
  const allApplications = []

  const newApplications = applications.filter((a) => a.id === -1)
  const lastNewApplication = newApplications.reduce((prev, current) => {
    return prev && prev.attributes.lead_id > current.attributes.lead_id ? prev : current
  }, null)

  if (lastNewApplication) {
    allApplications.push(lastNewApplication)
  }

  const oldApplications = applications.filter((a) => a.id !== -1)
  const lastOldApplications = oldApplications.reduce((prev, current) => {
    return prev && prev.id > current.id ? prev : current
  }, null)

  if (lastOldApplications) {
    allApplications.push(lastOldApplications)
  }

  switch (lead_type) {
    case 'medicare':
      return <MedicareApplications applications={allApplications} />
    default:
      return null
  }
}

const HeaderWithLogo = ({ displayText, displayIcon }) => {
  return (
    <>
      <div className={applicationStyles.headerWithLogo}>
        <img height="48px" width="48px" src={displayIcon} alt={displayText + ' icon'} />
        <span>{displayText}</span>
      </div>
    </>
  )
}

const getHeader = (lead_type) => {
  let displayIcon = getIconByLeadType(lead_type)
  let displayText = capitalizeFirstLetter(lead_type)
  return displayIcon && <HeaderWithLogo displayText={displayText} displayIcon={displayIcon} />
}

const MemberApplications = ({ applicationsData }) => {
  if (!applicationsData) {
    return null
  }
  return (
    <>
      {Object.entries(applicationsData).map(
        ([lead_type, applications]) =>
          applications &&
          applications.length > 0 && (
            <Grid item xs={12} key={lead_type}>
              {getHeader(lead_type)}
              {getApplicationsForLoi(lead_type, applications)}
            </Grid>
          ),
      )}
    </>
  )
}

export default MemberApplications
