import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Divider, List, ListItem, ListItemText } from '@mui/material'

import { getJson } from '../../../utils/requester'

import styles from './AccountInformation.module.scss'

const AccountInformation = () => {
  const [user, setUser] = useState({ email: '' })

  useEffect(() => {
    const loadProfile = () => {
      getJson('/member_portal/api/v1/user').then((result) => {
        setUser(result.data.attributes)
      })
    }
    loadProfile()
  }, [])

  return (
    <div className={styles.card}>
      <h2 className={styles.subheader}>Account Information</h2>
      <List>
        <ListItem className={styles.disabled}>
          <ListItemText primary="Email" secondary={user.email} />
        </ListItem>
        <ListItem>
          <Divider className={styles.divider} />
        </ListItem>
        <ListItem>
          <Link to="/settings/edit/password" className={styles.changePasswordText}>
            Change Password
          </Link>
        </ListItem>
      </List>
    </div>
  )
}

export default AccountInformation
