import { useMediaQuery, useTheme } from '@mui/material'

import ProgressCircleItem from '../ProgressCircleItem/ProgressCircleItem'
import ProgressCircleMobileItem from '../ProgressCircleItem/ProgressCircleMobileItem'

import styles from '../MedicareApplication/MedicareApplication.module.scss'

const displayNameMap = {
  recommendations: 'Recommendations',
  application: 'Application',
  enrollment: 'Enrollment',
  coverage: 'Coverage',
}

const ProgressComponent = ({ progressDetail }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={styles.progressWrapper}>
      {progressDetail.progress_status.map((status, index) =>
        isMobile ? (
          <ProgressCircleMobileItem
            displayNumber={index + 1}
            displayName={displayNameMap[status.status_name]}
            key={status.status_name}
            progressPercent={status.progress}
          />
        ) : (
          <ProgressCircleItem
            displayNumber={index + 1}
            displayName={displayNameMap[status.status_name]}
            key={status.status_name}
            progressPercent={status.progress}
          />
        ),
      )}
    </div>
  )
}

export default ProgressComponent
