import { Typography } from '@mui/material'

import sampleMedicareCard from 'images/member_portal/sample-medicare-card-only.svg'

import DragDropFileInputComponent from '../DragDropFileInputComponent/DragDropFileInputComponent'
import { calculateDays, getTaskDetailByType } from '../../../utils'
import { post } from '../../../utils/requester'

import styles from './UploadComponent.module.scss'
import formModalStyles from '../../MedicarePlanModal/MedicareFormModal.module.scss'

const addressUploadRequirementHeader = 'Any one of the following will suffice:'
const addressRequiredItemList = [
  "Valid Driver's License",
  'Property Tax Receipt',
  'Utility Bill',
  'Lease Agreement or mortgage statement',
  'Bank or credit card statement',
  'Insurance policy or bill',
]
const terminationLetterAlternateUploadRequirementHeader =
  "Alternatively, you can also provide a document showing that you've moved out of the plan’s service area or lost retirement benefits."

const UploadComponent = ({ taskDetail, onFileUpload }) => {
  const taskDetailMessage = getTaskDetailByType(taskDetail.attributes.type)
  const status = taskDetail.attributes.status || 'agent_veryfing'
  const displayName = taskDetailMessage['displayName'] || 'Document'
  const headerText = `Upload ${displayName}`
  const subHeaderText = taskDetailMessage['subHeaderText'] || 'Please upload document'
  const showRejectedMessage = status === 'rejected_by_agent' || status === 'rejected_by_carrier'
  const rejectedReason = showRejectedMessage ? taskDetail.attributes.customer_document_reason : ''
  const hideViewUrl = rejectedReason === 'Uploaded file failed virus scan'
  const viewImageDetail = () => {
    post(`/member_portal/api/v1/customer_to_do_tasks/${taskDetail.id}/view_document`).then(
      (result) => {
        window.open(result.data.attributes.full_url)
      },
    )
  }

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.headerText}>{headerText}</Typography>
      {showRejectedMessage && (
        <div className={styles.rejectMessage}>
          <span>You previously uploaded {displayName} </span>
          {!hideViewUrl && (
            <button onClick={viewImageDetail} className={styles.viewMoreLink}>
              (view here)
            </button>
          )}
          <span>
            {calculateDays(taskDetail.attributes.file_uploaded_at)}. It was not accepted because:{' '}
            {rejectedReason}
          </span>
        </div>
      )}
      <h6 className={styles.paragraphText}>{subHeaderText}</h6>

      {taskDetail.attributes.type === 'CustomerToDoTasks::Address' && (
        <>
          <h6 className={styles.paragraphText}>{addressUploadRequirementHeader}</h6>
          <ul className={styles.listItemText}>
            {addressRequiredItemList.map((row) => {
              return <li key={row}>{row}</li>
            })}
          </ul>
        </>
      )}

      {taskDetail.attributes.type === 'CustomerToDoTasks::MedicareDocument' && (
        <img
          className={formModalStyles.sampleImage}
          src={sampleMedicareCard}
          alt="sample medicare card"
        />
      )}

      {taskDetail.attributes.type === 'CustomerToDoTasks::TerminationLetter' && (
        <h6 className={styles.paragraphText}>
          {terminationLetterAlternateUploadRequirementHeader}
        </h6>
      )}
      <DragDropFileInputComponent taskDetail={taskDetail} onFileUpload={onFileUpload} />
    </div>
  )
}

export default UploadComponent
