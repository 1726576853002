import { useEffect, useState } from 'react'

import LoadingModal from './LoadingModal'
import MedicareFormModal from './MedicareFormModal'
import ResultModal from './ResultModal'
import { post } from '../../utils/requester'

const MedicarePlanModal = ({ application }) => {
  const leadId = application.attributes.lead_id
  const [modalPayload, setModalPayload] = useState({})
  const [flowState, setFlowState] = useState(1)
  const [resultStatus, setResultStatus] = useState('')
  const submitFindMyPlan = (modalPayload) => {
    setModalPayload(modalPayload)
    setFlowState(2)
  }
  useEffect(() => {
    const findRecommendation = () => {
      post(
        '/member_portal/api/v1/leads/' + leadId + '/applications/medicare/search_policy',
        modalPayload,
      )
        .then((result) => {
          const resultData = result.data.attributes
          if (resultData === 'success') {
            setResultStatus('success')
          } else {
            setResultStatus('fail')
          }
          setFlowState(3)
        })
        .catch(() => {
          setResultStatus('fail')
          setFlowState(3)
        })
    }
    if (flowState === 2 && modalPayload !== {}) {
      findRecommendation()
    }
  }, [leadId, flowState, modalPayload])

  if (flowState === 1) {
    return <MedicareFormModal submitFindMyPlan={submitFindMyPlan} />
  } else if (flowState === 2) {
    return <LoadingModal />
  } else {
    return <ResultModal resultStatus={resultStatus} />
  }
}
export default MedicarePlanModal
