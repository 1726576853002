import { useEffect, useState } from 'react'

import { Grid, LinearProgress, Typography, makeStyles } from '@mui/material'

import modalStyles from './MedicareFormModal.module.scss'

const LoadingModal = () => {
  const [progress, setProgress] = useState(0)
  const useStyles = makeStyles({
    root: {
      height: 10,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: '#DEE5EA',
    },
    bar: {
      borderRadius: 10,
      backgroundColor: '#007CC2',
    },
  })

  const classes = useStyles()
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          // Stop updates
          clearInterval(timer)
          return 100
        }
        return Math.min(oldProgress + 1, 100)
      })
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11}>
          <h2 className={modalStyles.header}>Searching for your Medicare plan!</h2>
        </Grid>
        <Grid item xs={10}>
          <Typography id="modal-modal-description" className={modalStyles.topDescription}>
            We are looking through our records to find your plan. Bear with us for a moment!
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={modalStyles.progress}
            classes={{
              root: classes.root,
              colorPrimary: classes.colorPrimary,
              bar: classes.bar,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default LoadingModal
