import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import List from '@mui/material/List'

import { isEmpty } from 'lodash'

import ProfileListItem from './ProfileListItem'
import { getJson } from '../../utils/requester'

import styles from './Profile.module.scss'

const Person = ({ attributes, id }) => {
  const { pathname } = useLocation()
  const [mailingAddress, setMailingAddress] = useState({})

  useEffect(() => {
    if (pathname.includes('profile/edit')) return

    getJson(`/member_portal/api/v1/people/${id}/contact_methods`).then((result) => {
      setMailingAddress(result.data[0] || {})
    })
  }, [id, pathname])

  return (
    <>
      <div className={styles.card}>
        <h2 className={styles.subheader}>Basic Info</h2>
        <List component="div">
          <ProfileListItem
            personId={id}
            fieldTitle="Name"
            fieldName="name"
            fieldValue={{ first_name: attributes.first_name, last_name: attributes.last_name }}
          />
          <ProfileListItem
            personId={id}
            fieldTitle="Date of birth"
            fieldName="birth_date"
            fieldValue={attributes.birth_date}
          />
          <ProfileListItem
            personId={id}
            fieldTitle="Gender"
            fieldName="gender"
            fieldValue={attributes.gender}
          />
          <ProfileListItem
            personId={id}
            contactMethodId={mailingAddress.id}
            fieldTitle="Address"
            fieldName="address"
            fieldValue={mailingAddress.attributes}
            displayFormat="address"
            lastItem={true}
            disableLink={isEmpty(mailingAddress)}
          />
        </List>
      </div>

      <div className={styles.card}>
        <h2 className={styles.subheader}>Family Info</h2>
        <List component="div">
          <ProfileListItem
            personId={id}
            fieldTitle="Marital Status"
            fieldName="marital_status"
            fieldValue={attributes.marital_status}
            lastItem={true}
          />
        </List>
      </div>
    </>
  )
}

export default Person
