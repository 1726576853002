import { isEmpty } from 'lodash'

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const policyType = (loi) => {
  if (loi === 'mortgage') {
    return capitalizeFirstLetter(loi) + ' Application'
  } else {
    return capitalizeFirstLetter(loi) + ' Insurance'
  }
}

const taskMessages = {
  'CustomerToDoTasks::Address': {
    displayName: 'Proof of Address Document',
    subHeaderText:
      'Please upload a document with your current address: 123 Maple St. Addison TX 75001 on it.',
  },
  'CustomerToDoTasks::MedicareAwardLetter': {
    displayName: 'Proof of Medicaid Award Letter',
    subHeaderText:
      'Please upload a copy of your most recent Medicaid award letter.  This letter would have been sent to you from your state Medicaid department.',
  },
  'CustomerToDoTasks::MedicareDocument': {
    displayName: 'Proof of Medicare',
    subHeaderText:
      'Please upload a picture of your Medicare card. A sample card is shown below for reference:',
  },
  'CustomerToDoTasks::TerminationLetter': {
    displayName: 'Proof of Termination Letter',
    subHeaderText:
      'Please upload proof of Termination Letter. The termination letter should be signed and dated by the beneficiary on company letterhead showing the reason for the loss of coverage (cannot be voluntary and must be involuntary). The letter must also include those who are losing coverage (the beneficiary and any household members), and the date the coverage will end.',
  },
}

export const getTaskDetailByType = (type) => {
  return taskMessages[type] || {}
}

export const calculateDays = (fromDate) => {
  if (isEmpty(fromDate)) {
    return 'today'
  }
  const oneDay = 24 * 60 * 60 * 1000
  const toDate = new Date()
  const numberOfDays = Math.round(Math.abs((new Date(fromDate) - toDate) / oneDay))
  if (numberOfDays === 0) {
    return 'today'
  } else if (numberOfDays === 1) {
    return '1 day ago'
  } else {
    return `${numberOfDays} days ago`
  }
}
