import Grid from '@mui/material/Grid'

const NotFound = () => {
  return (
    <Grid item xs={6} md={6}>
      <p>Not found</p>
    </Grid>
  )
}

export default NotFound
