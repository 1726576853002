const insuranceInfos = {
  life: {
    articles: {
      'Types of Life Insurance': 'https://assurance.com/life-insurance/types-of-life-insurance/',
      'How Does Life Insurance Work?':
        'https://assurance.com/life-insurance/how-does-life-insurance-work/',
      'What Is Accidental Death and Dismemberment Life Insurance?':
        'https://assurance.com/life-insurance/what-is-accidental-death-and-dismemberment-life-insurance/',
      'What Is A Life Insurance Annuity':
        'https://assurance.com/life-insurance/what-is-a-life-insurance-annuity/',
      'Is Life Insurance a Good Investment for Your Finances?':
        'https://assurance.com/life-insurance/is-life-insurance-a-good-investment-for-your-finances/',
      'What Is Permanent Life Insurance and How Does It Work?':
        'https://assurance.com/life-insurance/what-is-permanent-life-insurance-and-how-does-it-work/',
    },
    faqs: {
      'Can you have multiple life insurance policies?': [
        'Yes. You can have multiple policies from the same or different life insurance companies.',
        'For example, you can have a whole life insurance policy for a permanent need or a term life policy for a temporary need. That might include paying off your mortgage or your children’s college expenses if you were to die.',
      ],
      'How much life insurance do I need?': [
        'You should consider your current and ongoing expenses plus your future expenses like college or your funeral.',
      ],
      'What is a life insurance premium? What happens if I don’t pay?': [
        'A life insurance premium is the payment you make as your portion of the cost of an insurance policy.',
        'Typically, you will have a 30 or 31-day grace period. If you pay within this period, your policy continues.',
        'If you don’t pay within the grace period, your policy may lapse, depending on the type of policy. Nevertheless, with a permanent policy, your life insurer may use your cash value, if available, to cover premium payments.',
      ],
      'Can I get a life insurance policy on anyone?': [
        'Yes, but only if you have an “insurable interest” in that person. That means you would suffer financially if that person passed away.',
        'This usually means a spouse, domestic partner, live-in companion, business partner, or parent.',
      ],
      'Can I get life insurance with no health exam?': [
        'There are multiple life insurance options. Some don’t require a medical exam, while others do.',
      ],
    },
    quoteUrl: 'https://assurance.com/life',
  },
  auto: {
    articles: {
      'A Guide To Understanding Your Car Insurance Deductible':
        'https://assurance.com/auto-insurance/a-guide-to-understanding-your-car-insurance-deductible/',
      'What Is Comprehensive Insurance?':
        'https://assurance.com/auto-insurance/what-is-comprehensive-insurance/',
      'What Is High-risk Auto Insurance?':
        'https://assurance.com/auto-insurance/what-is-high-risk-auto-insurance/',
      'What is Pay Per Mile Car Insurance?':
        'https://assurance.com/auto-insurance/what-is-pay-per-mile-car-insurance/',
    },
    faqs: {
      'What kind of auto insurance coverage should I get?': [
        'Your auto insurance policy is a package of protections. Depending on where you live, certain components are required, while others are optional. Some states have required minimum amounts on the required coverages, but you can increase the protection if you feel it’s necessary. You want to ensure that you have adequate coverage in the event of an accident. While it’s better to be over-insured than under-insured, you’ll want to make sure you’re not paying more than you need.',
        'You can also increase or decrease your deductible based on your budget and personal comfort. Altering it does have a direct impact on what you pay each month. If you increase your deductible, you pay less a month for your policy. Decreasing it, on the other hand, means you pay more. Raising or lowering your deductible also affects your out-of-pocket costs. For instance, a high deductible means lower monthly premiums, but you’ll have to pay more out of pocket before your policy kicks in. Keep these factors in mind when choosing.',
        'Some of the most common auto insurance coverages include:',
        'Liability. Most states require drivers to carry liability insurance, and you need to have at least the minimum amount of coverage your state requires. This coverage consists of bodily injury and property damage.',
        'Comprehensive. Comprehensive coverage protects you if something happens to your car when you’re not driving it. Covered incidents may include fire, downed trees, hail, vandalism, and theft. While comprehensive is optional in most states, a lender may require it if you’re leasing or financing your vehicle.',
        'Collision. Collision protects you if you’re involved in an accident with another driver or you hit an object while you’re driving. The coverage helps to pay for the repair or replacement of your car. Like comprehensive coverage, collision is optional, but you may be required to have it if you’re leasing the car or you’re paying off a loan for it.',
        'Other types of insurance coverage include:',
        'Uninsured and underinsured motorist',
        'Medical payments (MedPay) coverage',
        'Personal injury protection (PIP), which is only available in some states',
        'Guaranteed auto protection (GAP)',
        'Rental reimbursement',
        'New car replacement',
        'Ride-sharing coverage',
        'Outside of the minimum requirements based on where you live, you get to choose the amount of coverage you want, what types of coverages you want, and what deductibles you want. An Assurance agent can help you make sense of your options and help you select the best protection (and amount of protection) for you.',
      ],
      "What's the difference between an insurance quote and an insurance premium?": [
        'An auto insurance quote gives you a general idea of what you can expect to pay based on the information you provide before you decide to purchase a policy. Insurers don’t consider every factor when generating your quote, though. This means is the actual amount you pay every month may be different from the initial quote an insurer provides.',
        'Your premium is the actual amount you pay for your policy to maintain your insurance coverage. You can choose to pay your premium monthly, bi-annually, or annually. Some insurers may offer discounts if you decide to pay the entire annual premium in full. Don’t hesitate to ask a carrier if they offer such a discount. You can also ask if the company offers any other types of discounts that can help lower your premium further.  Discounts that can help reduce your monthly or annual premium include:',
        'Discounts that can help reduce your monthly or annual premium include:',
        'Low mileage driving',
        'Multi-policy bundling',
        'Multi-car',
        'New car',
        'Anti-theft devices',
        'Good driver',
        'Good student',
        'Paperless billing and communication',
        'Electronic funds transfer',
        'Different auto insurers offer different discounts. Don’t hesitate to ask a carrier what types of discounts they might have and how much they can save you if you decide to get a policy with them.',
      ],
      'What information will I need to provide when purchasing an auto insurance policy?': [
        'When you’re shopping for a car insurance policy, you will need to provide some information to get the most accurate quote possible. You can save time and avoid having to guess by having the following information available when researching your options:',
        'The birthdates and occupations of everyone you plan to include on your policy',
        'The driver’s license information for everyone you plan to include on the policy',
        'Your driving record, as well as the driving record(s) of everyone else who you want to include on your policy',
        'The car’s vehicle identification number (VIN)',
        'The address where you park the car',
        'How far you drive your vehicle every day',
        'Whether you own the vehicle or you’re leasing/financing it',
        'Your bank information or credit card number (which you’ll need to provide for premium payments)',
        'Having the information you need to provide before you start shopping for auto insurance can help to expedite the process. It can also help to make things easier and much less stressful.',
      ],
    },
    quoteUrl:
      'https://choice.assurance.com/quote?MDRefCode=assurance-iq-direct&utm_source=assurancecom&utm_term=autoproductp',
  },
  home: {
    articles: {
      'Home Insurance Riders Explained': '',
      'What Is an Insurance Limit?': '',
      'What Is an Insurance Peril?': '',
      'What’s a Home Insurance Deductible?': '',
      'What Is Personal Liability Insurance?': '',
      'What Is Loss of Use Coverage?': '',
    },
    faqs: {
      "How much homeowner's insurance coverage should I get?": [
        'Buying a home is one of the biggest investments you’ll ever make. A homeowner’s insurance policy helps to protect you financially if something ever happens to it. Which can leave you wondering, how much insurance should you buy?',
        'If something were to happen to your home, the last thing you’d want to discover is that you don’t have enough insurance to cover the cost of rebuilding it and replacing the items inside. In other words, you want to make sure your policy provides enough protection that you can afford to rebuild your house from the ground up (dwelling coverage). There are several factors to consider here, such as:',
        'Material and construction costs in your area',
        'New building codes',
        'Any upgrades or remodeling you may have done since your home was built',
        'Additional rooms or structures (such as a porch or deck) you’ve added',
        'You’ll want to make sure that you have enough personal property protection to cover the cost of replacing items inside of your home. That includes things like your clothing, electronics, jewelry, furniture, appliances, and more.',
        'In addition to dwelling and personal property coverage, you’ll also want to ensure that you have sufficient liability insurance. Your liability coverage protects you should someone get hurt on your property, whether they slip on the steps outside, fall off of a trampoline in the backyard, or get bit by your dog. Speaking of dogs, you should be aware that many insurance companies consider certain breeds “high-risk,” which could affect the cost of your policy. Liability insurance can help cover the cost of any legal or medical bills you might be responsible for after someone gets hurt while visiting your home.',
        'Finally, you might also consider additional living expenses (ALE). This section of a home insurance policy can help cover the cost of a hotel and food while you wait for your home to be repaired or rebuilt. Most policies use a percentage of your extended dwelling coverage to calculate your ALE, but you can increase or decrease the amount based on your specific needs, budget, and comfort level.',
      ],
      'What types of information will I need to provide to get a quote for a home insurance policy?':
        [
          'Before you can get a quote for a homeowner’s insurance policy, you will need to provide some information. Providing as much accurate information as possible will help to ensure the most accurate quote.',
          'One thing to keep in mind here is that the actual cost of your home insurance policy may differ from the quote you receive initially. Providing as much information as possible (and providing accurate information) will help to ensure that your quote is as accurate as possible.',
          'Here is a list of some of the personal information you may be asked to provide when getting a quote for home insurance:',
          'Your full legal name',
          'Your date of birth',
          'Your current occupation',
          'Your marital status',
          'Your previous address',
          'Information about any pets you have (if you have dogs, you’ll need to disclose their breeds)',
          'If you have items such as a trampoline or pool (in-ground or above ground)',
          'You’ll also need to provide information about the home you’re buying the policy for, including such things as:',
          'The address of the property',
          'When the house was built',
          'The size of the house',
          'Building materials used to construct it',
          'Information about the roof, such as its age and the materials used',
          'Any recent upgrades that may have been added, such as a recent kitchen remodel, new water heater or HVAC system, or room addition',
          'The appliances and other amenities in the home',
          'The value of the personal belongings you keep (or plan to keep) in the home',
          'Insurance appraisals',
          'The lender requirements (if you’re financing the purchase of the property)',
          'Inspection reports',
          'While most insurers require similar information, there may be some differences here and there. With Assurance, you only need to enter your information once to receive quotes from multiple carriers. Having all of the above information ready to go ahead of time will help to save a lot of time and avoid frustration.',
        ],
      'Will I need to get an inspection done before I can get a quote for home insurance?': [
        'The short answer is that you don’t necessarily need a home inspection before you can get a quote for homeowner’s insurance. Every insurance company has its own requirements. Some may require one while others don’t.',
        'While it’s true that you might not require a home inspection in some instances, you may have to have one done before getting an insurance policy if your house is over a certain age and hasn’t been inspected in a while. If you do need one, you’ll likely need a 4-point inspection. It’s particularly common for homes older than 25 years or that have a history of repair issues.',
        'The goal of a 4-point inspection is for an insurance company to assess its risk in offering you a policy. It also helps them determine how much your policy will cost. The inspection itself focuses on four essential parts of your home:',
        'The plumbing',
        'The electrical',
        'The HVAC',
        'The roof',
        'If your home fails the inspection, the insurance company may request that you fix the issues it found before it approves your application for a home insurance policy.',
      ],
    },
    quoteUrl:
      'https://choice.assurance.com/quote?MDRefCode=assurance-iq-direct&utm_source=assurancecom&utm_term=homeproductp',
  },
  health: {
    articles: {
      'Copays, Deductibles, and Coinsurance':
        'https://assurance.com/health-insurance/copays-deductibles-and-coinsurance/',
      'Types of Health Insurance Plans and Networks':
        'https://assurance.com/health-insurance/types-of-health-insurance-plans-and-networks/',
      'What Is a Qualifying Life Event?':
        'https://assurance.com/health-insurance/what-is-a-qualifying-life-event/',
      'The Health Insurance Marketplace':
        'https://assurance.com/health-insurance/the-health-insurance-marketplace/',
      'Understanding Health Insurance Subsidies':
        'https://assurance.com/health-insurance/understanding-health-insurance-subsidies/',
      'How To Get Health Insurance Between Jobs':
        'https://assurance.com/health-insurance/how-to-get-health-insurance-between-jobs/',
    },
    faqs: {
      'What type of health insurance should I get?': [
        'The first step toward identifying which healthcare insurance plan is right for your situation is to understand your specific healthcare needs and financial situation. These are both important factors that determine what plan is right for you.',
        'If you don’t see the doctor often and maintain relatively good health, opting for a health insurance plan with a higher deductible and lower premiums may help you save money. However, a downside of this type of plan is that you leave yourself susceptible to the risk of large, unexpected medical bills in the case of an accident.',
        'If you prefer paying higher premiums with lower cost-sharing or require more than basic preventative medical care, you could consider plans with lower deductibles and coinsurance predictable costs.',
      ],
      'When can I enroll in an individual health insurance plan?': [
        'Most states have Open Enrollment for the Affordable Care Act (ACA) health insurance beginning November 1st through December 15th. There are, however, states that have permanently extended open enrollment. It is a requirement that an individual is a resident of the state in which their health insurance plan is filed.',
        'Some states with permanently extended open enrollment periods as of 2021 are:',
        'Colorado: November 1, 2020, through January 15, 2021',
        'California: November 1, 2020, to January 31, 2021',
        'District of Columbia: November 1, 2020, to January 31, 2021',
      ],
      'Special Enrollment Period': [
        'A special enrollment period has been in effect since February 15, 2021, and will continue through August 15, 2021, for individuals and families with ACA marketplace coverage as a direct response to the Covid-19 health pandemic. This special enrollment period is only valid for residents of the 36 states that the Health Insurance Marketplace serves.',
        'Typically, the Open Enrollment period at the end of the year is the only time to buy ACA-qualified health insurance via the private market and you cannot buy it after the state-specific cut-off date. However, there are qualifying life events that give you a sixty-day period to also enter into enrollment.',
        'Some individuals may qualify for Special Enrollment if they lose coverage previous held through a parent, spouse, or another family member in these situations:',
        'You can no longer remain on your parent’s health insurance plan as you’ve turned 26 years old.',
        'A parent or guardian lost the employment that provided you with health insurance.',
        'You lose health insurance through a family member’s employer because your family member loses health coverage for their dependents',
        'Legal separation or divorce from a spouse resulting in loss of health insurance coverage.',
        'A family member dies that previously provided you with health insurance.',
        'You recently became a U.S. citizen, making you newly eligible for an ACA marketplace insurance plan.',
        'You recently gained membership to a federally recognized Native American Indian tribe.',
        'You recently gained the status of an Alaskan Native Claims Settlement Act (ANCSA) shareholder.',
        'You began or completed service as an AmeriCorps State and National, VISTA, or NCCC member.',
        'You are newly released from incarceration.',
      ],
      'What is ACA health insurance?': [
        'Sometimes referred to as Obamacare, the Affordable Care Act of 2010 defines a list of ten essential health benefits that individual medical plans offered on the marketplace must cover in order to be a recognized ACA plan.',
      ],
      'What does an ACA health insurance plan provide?': [
        'The list of legally required services present in ACA health insurance plans are outpatient, emergency services, surgery, maternity and newborn infant care, mental health services, prescription drugs, preventative and rehabilitative care, along with lab services and pediatrics. This type of health insurance plan comes with higher premiums than non-ACA short-term health plans but offers much greater coverage levels.',
      ],
      'Does ACA health insurance coverage start right away?': [
        'In most cases, a waiting period is required prior to your health insurance coverage beginning.',
        'You won’t begin paying your premiums on new healthcare coverage until that coverage begins on January 1st. If the reason you qualify for an ACA plan is a qualifying life event, your coverage may not begin for at least two weeks.',
      ],
      'How much does an ACA health insurance plan cost?': [
        'The specific cost of different plans’ premiums varies between plans. However, subsidies do exist and may be available.',
        'Subsidies may cover part of your premium or out-of-pocket if your income is less than 4x the federal poverty level. ACA plans help manage expenses because of the cap on what you are required to pay. In 2020, the maximum out-of-pocket cost was $8,200. For families, this was $16,000.',
      ],
      'What types of ACA plans can I get through the Health Insurance Marketplace?': [
        'The ACA marketplace offers four levels of health insurance plans, each one paying a different portion of your health care bills.',
        'An estimation of what an insurance company will pay per level of insurance plan follows:',
        'Bronze 60%',
        'Silver 70%',
        'Gold 80%',
        'Platinum 90%',
      ],
      'How can I save money on health insurance costs?': [
        'Individuals enrolled through the Health Insurance Marketplace may be able to lower their per-monthly out-of-pocket costs that can help make insurance plans more affordable for more individuals.',
        'You must meet specific requirements to be qualified for tax credits:',
        'You are enrolled in coverage through the marketplace, excluding “catastrophic coverage.”',
        'You are unable to gain insurance through employer-sponsored insurance plans.',
        'You do not qualify for government-run programs like TRICARE, CHIP, Medicaid, or medicare',
        'Your income is at least 100%, though not exceeding 400% of the federal poverty line.',
        'You’re married and file taxes jointly.',
        'You are not listed as a dependent on anyone else’s tax return.',
        'You are not listed as a dependent on anyone else’s tax return.',
        'The marketplace will determine your eligibility. You can see if you’re eligible by answering a few simple questions.',
      ],
      'Can I buy or change individual health insurance plan outside of the Open Enrollment Period?':
        [
          'You can buy either indemnity or short-term health insurance at any time but cannot change or purchase ACA health insurance outside of the standard Open Enrollment period, unless you have a qualifying life event.',
          'Life qualifying events include:',
          'Loss of health coverage through the loss of a job due to no fault of your own.',
          'Having a child or adopting a child.',
          'Moving from one health insurance provider’s coverage area to another’s territory.',
          'Getting married',
          'Turning 26 years old and subsequently losing previously held health insurance that came as a result of losing your parents’ health insurance.',
          'You are not listed as a dependent on anyone else’s tax return.',
          'The cancellation of your current healthcare insurance plan.',
        ],
    },
    quoteUrl: 'https://assurance.com/health',
  },
}

export const getInsuranceInfo = (loi) => {
  return insuranceInfos[loi] || {}
}
