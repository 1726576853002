import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import Box from '@mui/material/Box'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import UpdateButton from '../../Profile/UpdateButton'
import { put } from '../../../utils/requester'

import styles from '../Settings.module.scss'

const EditPassword = () => {
  const [formState, setFormState] = useState({
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  })
  const [showPassword, setShowPassword] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showNewPasswordConfirmation: false,
  })
  const [errors, setErrors] = useState({
    passwordMismatch: false,
    wrongCurrentPassword: false,
  })
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    navigate('/settings')
  }

  const handleClickShowPassword = (key) => {
    setShowPassword({
      ...showPassword,
      [key]: !showPassword[key],
    })
  }

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const updatePassword = () => {
    setErrors({ ...errors, ...{ passwordMismatch: false, wrongCurrentPassword: false } })
    if (!passwordConditionSatisfied('all') || formState['current_password'] === '') {
      return
    }
    if (formState['new_password'] === formState['new_password_confirmation']) {
      const body = {
        old_password: formState['old_password'],
        new_password: formState['new_password'],
      }
      return put('/member_portal/api/v1/user/password', body).then((response) => {
        if (response.errors) {
          setErrors({ ...errors, ...{ wrongCurrentPassword: true, passwordMismatch: false } })
          setFormState({
            ...formState,
            ...{
              old_password: '',
              new_password: '',
              new_password_confirmation: '',
            },
          })
        } else {
          navigate('/settings')
        }
      })
    } else {
      setErrors({ ...errors, ...{ passwordMismatch: true, wrongCurrentPassword: false } })
      setFormState({ ...formState, ...{ new_password: '', new_password_confirmation: '' } })
    }
  }

  const passwordConditionSatisfied = (condition) => {
    const newPassword = formState['new_password']
    switch (condition) {
      case 'length':
        return newPassword.length >= 8
      case 'number':
        return /\d/.test(newPassword)
      case 'lower':
        return /[a-z]/.test(newPassword)
      case 'upper':
        return /[A-Z]/.test(newPassword)
      default:
        return (
          newPassword.length >= 8 &&
          /\d/.test(newPassword) &&
          /[a-z]/.test(newPassword) &&
          /[A-Z]/.test(newPassword)
        )
    }
  }

  function passwordCheckIcon(satisfied) {
    if (satisfied) {
      return <CheckCircleIcon className={styles.conditionSatisfied} />
    } else {
      return <CheckCircleOutlinedIcon color="disabled" />
    }
  }

  function passwordCheck(condition, conditionText) {
    const satisfied = passwordConditionSatisfied(condition)
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>{passwordCheckIcon(satisfied)}</Grid>
        <Grid item>{conditionText}</Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      PaperProps={{
        'aria-label': 'change password',
      }}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={styles.dialogTitle}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} align="center">
            Change Password
          </Box>
          <Box>
            <IconButton
              aria-label="close modal"
              id="dismiss-modal"
              onClick={handleClose}
              className={styles.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container className={styles.dialogForm}>
          <Grid item xs={12}>
            <Grid container direction={'column'} spacing={4} justify="center">
              <Grid item>
                <TextField
                  id="old-password"
                  name="old_password"
                  label="Current password"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={formState['old_password']}
                  type={showPassword['showOldPassword'] ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('showOldPassword')}
                        >
                          {showPassword['showOldPassword'] ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors['wrongCurrentPassword'] && (
                  <p className={styles.errorText}>Oops! The password you entered is incorrect.</p>
                )}
              </Grid>
              <Grid item>
                <TextField
                  id="new-password"
                  name="new_password"
                  label="New password"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={formState['new_password']}
                  type={showPassword['showNewPassword'] ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('showNewPassword')}
                        >
                          {showPassword['showNewPassword'] ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formState['new_password'].length > 0 && (
                  <ul>
                    <li className={styles.bold}>Your password must contain</li>
                    {passwordCheck('length', 'At least 8 characters')}
                    {passwordCheck('number', 'At least 1 number')}
                    {passwordCheck('lower', 'At least 1 lower case letter')}
                    {passwordCheck('upper', 'At least 1 upper case letter')}
                  </ul>
                )}
              </Grid>
              <Grid item>
                <TextField
                  id="new-password-confirmation"
                  name="new_password_confirmation"
                  label="Confirm new password"
                  variant="outlined"
                  onChange={handleChange}
                  fullWidth
                  value={formState['new_password_confirmation']}
                  type={showPassword['showNewPasswordConfirmation'] ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('showNewPasswordConfirmation')}
                        >
                          {showPassword['showNewPasswordConfirmation'] ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors['passwordMismatch'] && (
                  <p className={styles.errorText}>Oops! The password you entered doesn’t match.</p>
                )}
              </Grid>
              <Grid item>
                <UpdateButton handler={updatePassword} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default EditPassword
