import { NavLink } from 'react-router-dom'
import { useMemo, useState } from 'react'

import { Grid } from '@mui/material'

import clsx from 'clsx'
import { isEmpty } from 'lodash'

import LinkPolicyComponent from '../MedicareApplication/LinkPolicyComponent'
import NeedHelpComponent from './NeedHelpComponent'
import PolicyCard from '../MemberApplications/PolicyCard'
import ProgressComponent from './ProgressComponent'
import ToDoTasks from '../ToDoTasks/ToDoTasks'
import { getJson } from '../../utils/requester'

import styles from './MedicareProgressApplication.module.scss'
import medAppStyles from '../MedicareApplication/MedicareApplication.module.scss'

const CompleteProfileComponent = ({ progressDetail }) => {
  return (
    <>
      <div className={medAppStyles.title}>Get Your Medicare Plan Recommendation:</div>
      <div className={medAppStyles.subTitle}>
        The more info you give us, the more we can help provide plan recommendations based on your
        needs, to ultimately help find the plan with the benefits you want at a reasonable cost.
      </div>
      {!progressDetail.profile_completed && (
        <NavLink to={'/profile'}>
          <button className={clsx(styles.button, styles.whiteText, medAppStyles.button)}>
            <span>Complete Profile</span>
          </button>
        </NavLink>
      )}
    </>
  )
}

const ViewPlansComponent = ({ leadToken, filled }) => {
  return (
    <a
      className={clsx(styles.button, !filled && medAppStyles.buttonNotFilled)}
      href={`/getmedicare?token=${leadToken}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>View Plans</span>
    </a>
  )
}

const EditProfileComponent = ({ leadToken }) => {
  return (
    <>
      <div className={medAppStyles.title}>Get Your Medicare Plan Recommendation:</div>
      <div className={medAppStyles.subTitle}>
        The more info you give us, the more we can help provide plan recommendations based on your
        needs, to ultimately help find the plan with the benefits you want at a reasonable cost.
      </div>
      <div className={styles.buttonsWrapper}>
        <ViewPlansComponent leadToken={leadToken} filled={true} />

        <NavLink className={styles.buttonNotFilled} to={'/profile'}>
          <span>Edit Profile</span>
        </NavLink>
      </div>
    </>
  )
}

const PlansFoundComponent = ({ leadId, leadToken }) => {
  const [planDetail, setPlanDetail] = useState({})
  useMemo(() => {
    const loadMedicareApplication = () => {
      getJson('/member_portal/api/v1/leads/' + leadId + '/plans/medicare').then((result) => {
        setPlanDetail(result.data.attributes)
      })
    }
    loadMedicareApplication()
  }, [leadId])

  if (!planDetail.total_count) {
    return null
  }

  return (
    <>
      <div className={medAppStyles.quoteInfo}>
        <span>
          We’ve found <b>{planDetail.total_count}</b> Medicare plans in your Zip Code
        </span>
        <ViewPlansComponent leadToken={leadToken} filled={false} />
      </div>
      <hr className={styles.horizontalBreakLine} />
    </>
  )
}

const MedicareProgressApplication = ({ application, medicareData, progressDetail, handleOpen }) => {
  const policyExist = application.attributes.policy_found === true
  const leadId = application.attributes.lead_id
  const leadToken = application.attributes.lead_token || ''

  if (isEmpty(progressDetail)) {
    return null
  }

  const recommendation_progress =
    (progressDetail &&
      progressDetail.progress_status.find((obj) => obj.status_name === 'recommendations')) ||
    {}
  const application_progress =
    (progressDetail &&
      progressDetail.progress_status.find((obj) => obj.status_name === 'application')) ||
    {}
  if (recommendation_progress.progress === 100 && application_progress.progress === 100) {
    return (
      <>
        <Grid className={medAppStyles.wrapper}>
          <ProgressComponent progressDetail={progressDetail} />
          <PolicyCard application={application} in_progress={true} />
          <hr className={styles.horizontalBreakLine} />
          <ToDoTasks application={application} medicareData={medicareData} />
        </Grid>
      </>
    )
  }

  if (recommendation_progress.progress === 100) {
    return (
      <>
        <Grid className={medAppStyles.wrapper}>
          <ProgressComponent progressDetail={progressDetail} />
          <EditProfileComponent leadToken={leadToken} />
          <hr className={styles.horizontalBreakLine} />
          <NeedHelpComponent medicareData={medicareData} />

          {!policyExist && (
            <>
              <hr className={styles.horizontalBreakLine} />
              <LinkPolicyComponent progressDetail={progressDetail} handleOpen={handleOpen} />
            </>
          )}

          <ToDoTasks application={application} medicareData={medicareData} />
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid className={medAppStyles.wrapper}>
        <ProgressComponent progressDetail={progressDetail} />
        <CompleteProfileComponent progressDetail={progressDetail} />
        <hr className={styles.horizontalBreakLine} />

        <PlansFoundComponent leadId={leadId} leadToken={leadToken} />

        <NeedHelpComponent medicareData={medicareData} />

        {!policyExist && (
          <>
            <hr className={styles.horizontalBreakLine} />
            <LinkPolicyComponent progressDetail={progressDetail} handleOpen={handleOpen} />
          </>
        )}

        <ToDoTasks application={application} medicareData={medicareData} />
      </Grid>
    </>
  )
}

export default MedicareProgressApplication
