import { useState } from 'react'

import { Grid, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import TelephoneNumber from '../../TelephoneNumber/TelephoneNumber'
import { calculateDays, getTaskDetailByType } from '../../../utils'
import { post } from '../../../utils/requester'

import styles from './PreviousUploadedComponent.module.scss'

const PreviousUploadedComponent = ({ medicareData, taskDetail, status }) => {
  const hideViewUrl = status === 'file_checking' || status === 'bad_file'
  const taskDetailMessage = getTaskDetailByType(taskDetail.attributes.type)
  const displayName = taskDetailMessage['displayName'] || 'Document'
  const title = `Upload ${displayName}`
  const [isExpanded, setIsExpanded] = useState(false)
  const performExpandCollapse = () => {
    setIsExpanded(!isExpanded)
  }

  const viewImageDetail = () => {
    post(`/member_portal/api/v1/customer_to_do_tasks/${taskDetail.id}/view_document`).then(
      (result) => {
        window.open(result.data.attributes.full_url)
      },
    )
  }

  let buttonText = 'Expand'
  let iconType = <KeyboardArrowDownIcon />
  if (isExpanded) {
    buttonText = 'Collapse'
    iconType = <KeyboardArrowUpIcon />
  }
  return (
    <Grid className={styles.wrapper}>
      <div className={styles.leftLineWrapper}>
        <div className={styles.headerWrapper}>
          <Typography className={styles.headerText}>{title}</Typography>
          <div className={styles.collapseExpandText} onClick={performExpandCollapse}>
            {buttonText} {iconType}
          </div>
        </div>
        {isExpanded && (
          <div className={styles.bodyWrapper}>
            <span>You previously uploaded {displayName} </span>
            {!hideViewUrl && (
              <button onClick={viewImageDetail} className={styles.viewMoreLink}>
                (view here)
              </button>
            )}

            <span>
              {calculateDays(taskDetail.attributes.file_uploaded_at)}. It is currently under carrier
              review. No further action is currently required. You cannot make any further updates
              digitally at this point. If you need to update this information, please call our
              customer care agent at{' '}
              <TelephoneNumber phoneNumber={medicareData.upload_document_help_phone} />
            </span>
          </div>
        )}
        {!isExpanded && (
          <div className={styles.bodyWrapperCollapsed}>
            <span>Status: Pending Carrier Review</span>
          </div>
        )}
      </div>
    </Grid>
  )
}

export default PreviousUploadedComponent
