import { NavLink } from 'react-router-dom'

import { capitalizeFirstLetter } from '../../utils'

import styles from './PolicyCard.module.scss'

const PolicyCard = ({ application, in_progress }) => {
  const carrier_name = application.attributes.carrier_name || ''
  const policy_name = application.attributes.policy_name || 'Assurance Policy'
  const displayLogoText = capitalizeFirstLetter(carrier_name)
  const titleTest = in_progress
    ? 'You’ve applied for:'
    : 'Congrats - Your coverage is now effective 🎉'
  return (
    <div className={styles.policyCardWrapper}>
      <div className={styles.title}>{titleTest}</div>
      <div className={styles.quoteInfo}>
        <div className={styles.carrierAndPolicy}>
          <div className={styles.displayLogoText}>{displayLogoText}</div>
          <span>{policy_name}</span>
        </div>
        <div className={styles.ctas}>
          <a
            className={styles.buttonNotFilled}
            href={application.attributes.pdf_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>View Plan Details</span>
          </a>
          <NavLink to={'/contact'} className={styles.askQuestion}>
            <span>Ask a question</span>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default PolicyCard
