import { Outlet, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import Loader from 'shared/components/Loader'
import { isEmpty } from 'lodash'

import PageLayout from '../Layouts/PageLayout/PageLayout'
import Person from './Person'
import { getJson } from '../../utils/requester'

import styles from './Profile.module.scss'

const Profile = () => {
  const { pathname } = useLocation()
  const [people, setPeople] = useState([])

  useEffect(() => {
    if (pathname.includes('profile/edit')) return

    getJson('/member_portal/api/v1/people').then((result) => {
      setPeople(result.data)
    })
  }, [pathname])

  return (
    <PageLayout>
      <h1 className={styles.header}>Profile</h1>
      {isEmpty(people) ? (
        <Loader />
      ) : (
        people.map((person) => {
          return <Person attributes={person.attributes} id={person.id} key={person.id} />
        })
      )}
      <Outlet />
    </PageLayout>
  )
}

export default Profile
