import { NavLink } from 'react-router-dom'

import clsx from 'clsx'

import { getIconByLeadType } from '../../utils/icons'

import styles from './SidebarNavItem.module.scss'

const NavItemWithLogo = ({ navItem, displayIcon }) => {
  return (
    <>
      <span className={styles.navWithIcon}>
        <img height="40px" width="35px" src={displayIcon} alt={navItem.name + ' icon'} />{' '}
        {navItem.name}
      </span>
    </>
  )
}

const SidebarNavItem = ({ navItem }) => {
  const getNavItemContent = (navItem) => {
    let displayIcon = getIconByLeadType(navItem.icon)
    return displayIcon ? (
      <NavItemWithLogo navItem={navItem} displayIcon={displayIcon} />
    ) : (
      navItem.name
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerNav}>{navItem.name}</div>
      <ul className={styles.list}>
        {navItem.children.map((child) => (
          <li key={child.name}>
            <NavLink
              to={child.route}
              className={({ isActive }) => clsx(styles.link, isActive && styles.activeLink)}
            >
              {getNavItemContent(child)}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SidebarNavItem
