import Grid from '@mui/material/Grid'

import styles from './PageLayout.module.scss'

const PageLayout = ({ children, gridContainerProps, gridItemProps }) => {
  return (
    <Grid container className={styles.root} {...gridContainerProps}>
      <Grid item xs={12} sm={10} lg={8} xl={6} {...gridItemProps}>
        {children}
      </Grid>
    </Grid>
  )
}

export default PageLayout
