import styles from './Banner.module.scss'

const Banner = ({ text }) => {
  return (
    <div className={styles.greetingWrapper}>
      <div className={styles.greeting}>{text}</div>
    </div>
  )
}

export default Banner
