import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import SanitizedHTML from 'shared/components/SanitizedHTML/SanitizedHTML'

const AccordionItems = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <Accordion key={item.detail}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>{item.summary}</AccordionSummary>
          <AccordionDetails>
            <SanitizedHTML dirtyHtml={item.detail} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

export default AccordionItems
