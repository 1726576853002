import { Outlet } from 'react-router-dom'

import AccountInformation from './AccountInformation/AccountInformation'
import ContactPreferences from './ContactPreferences/ContactPreferences'
import PageLayout from '../Layouts/PageLayout/PageLayout'

import styles from './Settings.module.scss'

const Settings = () => {
  return (
    <PageLayout>
      <h1 className={styles.header}>Settings</h1>
      <AccountInformation />
      <ContactPreferences />
      <Outlet />
    </PageLayout>
  )
}

export default Settings
