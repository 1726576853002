import { getApi } from './axios-api-standalone'

export const getJson = (url) => {
  return getApi()
    .get(url)
    .then((result) => {
      return result.data
    })
}

function request(url, method, data, options = {}) {
  return getApi()
    .request({
      ...options,
      url,
      method,
      data,
    })
    .then((result) => {
      return result.data
    })
}

export const put = (url, body) => {
  return request(url, 'PUT', body)
}

export const post = (url, body) => {
  return request(url, 'POST', body)
}

export const uploadFile = (url, payload, method = 'POST') => {
  return request(url, method, payload)
}

export const uploadFileWithProgressBar = (url, payload, options) => {
  return request(url, 'POST', payload, options)
}
