import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'
import Grid from '@mui/material/Grid'

import EditAddress from '../EditAddress'
import EditBirthDate from '../EditBirthDate'
import EditGender from '../EditGender'
import EditMaritalStatus from '../EditMaritalStatus'
import EditName from '../EditName'
import NotFound from '../../NotFound/NotFound'

import profileStyles from '../Profile.module.scss'

const EditProfileField = () => {
  let { state } = useLocation()
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()

  const getComponent = () => {
    switch (state.fieldName) {
      case 'name':
        return <EditName defaultValue={state.fieldValue} personId={state.personId} />
      case 'birth_date':
        return <EditBirthDate defaultValue={state.fieldValue} personId={state.personId} />
      case 'gender':
        return <EditGender defaultValue={state.fieldValue} personId={state.personId} />
      case 'marital_status':
        return <EditMaritalStatus defaultValue={state.fieldValue} personId={state.personId} />
      case 'address':
        return (
          <EditAddress
            defaultValue={state.fieldValue}
            personId={state.personId}
            contactMethodId={state.contactMethodId}
          />
        )
      default:
        return <NotFound />
    }
  }

  const handleClose = () => {
    setOpen(false)
    navigate('/profile')
  }

  return (
    <Grid item xs={12} sm={9}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle className={profileStyles.dialogTitle}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} align="center">
              {state.fieldTitle}
            </Box>
            <Box>
              <IconButton
                id="dismiss-modal"
                onClick={handleClose}
                className={profileStyles.closeIcon}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={profileStyles.dialogContent}>
          <Grid container spacing={4} justify="center" className={profileStyles.dialogForm}>
            <Grid item xs={12} sm={6}>
              {getComponent()}
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText>
                Note: Any information you update here will not be automatically shared with the
                carrier. If you wish to update your carrier, please contact us at
                <br />
                1-833-377-1105 TTY 711
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default EditProfileField
