import { useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import PageLayout from '../Layouts/PageLayout/PageLayout'
import { capitalizeFirstLetter } from '../../utils'
import { getIconByLeadType } from '../../utils/icons'
import { getInsuranceInfo } from '../../utils/insuranceInfo'

import styles from './InsuranceInfo.module.scss'

const createArticleData = (name, route) => {
  return { name, route }
}

const createFaqData = (title, descriptions) => {
  return { title, descriptions }
}

const InsuranceInfo = ({ loi }) => {
  const [expandedPanel, setExpandedPanel] = useState(null)
  const displayIcon = getIconByLeadType(loi)
  const displayText = capitalizeFirstLetter(loi)
  const insuranceInfoData = getInsuranceInfo(loi)
  const quoteUrl = insuranceInfoData['quoteUrl']
  const articles = Object.entries(insuranceInfoData['articles'] || {}).map(([key, value]) => {
    return createArticleData(key, value)
  })
  const faqs = Object.entries(insuranceInfoData['faqs'] || {}).map(([key, value]) => {
    return createFaqData(key, value)
  })

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null)
  }

  return (
    <PageLayout>
      <div className={styles.headerWithLogo}>
        <img height="48px" width="48px" src={displayIcon} alt={displayText + ' icon'} />
        <span>{displayText}</span>
      </div>

      <Card className={styles.cardWrapper}>
        <h2 className={styles.header}>Shop and Compare {displayText} Insurance Quotes</h2>
        <div>
          We uncomplicate the process to find {displayText} insurance policy to meet your needs
        </div>
        <button className={styles.button} onClick={() => window.open(quoteUrl)}>
          <span>Get a Quote</span>
        </button>
      </Card>

      <Card className={styles.cardWrapper}>
        <h2 className={styles.header}>{displayText} Insurance Articles:</h2>
        <div>
          <ul className={styles.articlesWrapper}>
            {articles.map((loiData) => (
              <li className={styles.articleRow} key={loiData.name}>
                <a href={loiData.route} target="_blank" rel="noopener noreferrer">
                  {loiData.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Card>

      <h2 className={styles.header}>{displayText} Insurance Frequently Asked Questions</h2>
      <br />
      <div className={styles.faq}>
        {faqs.map((faqData, index) => (
          <Accordion
            expanded={expandedPanel === index}
            onChange={handleChange(index)}
            key={faqData.title}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${index}-content`}
              id={`${index}-header`}
            >
              {faqData.title}
            </AccordionSummary>
            <AccordionDetails className={styles.faqDetails}>
              {faqData.descriptions.map((description) => (
                <Typography className={styles.descriptionParagraph} key={description}>
                  {description}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </PageLayout>
  )
}

export default InsuranceInfo
