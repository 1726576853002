import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import styles from './HelpCenterCard.module.scss'

const HelpCenterCard = ({ src, altText, header, text, faqItem }) => {
  return (
    <Card className={styles.root} component={Link} to="/help_center/faq" state={{ faqItem }}>
      <img alt={altText} src={src} />
      <CardContent>
        <div className={styles.header}>{header}</div>
        <div className={styles.text}>{text}</div>
      </CardContent>
    </Card>
  )
}

export default HelpCenterCard
