import InputMask from 'react-input-mask'
import { useState } from 'react'

import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'

import sampleMedicare from 'images/member_portal/sample-medicare.svg'

import formModalStyles from '../MedicarePlanModal/MedicareFormModal.module.scss'

const normalizeInputValue = (input) => {
  return input.replace(/[_-]/g, '')
}

const MedicareFormModal = ({ submitFindMyPlan }) => {
  const [formError, setFormError] = useState('')
  const handleFindMyPlan = (event) => {
    event.preventDefault()
    let errorText = 'All fields are mandatory. Please include a valid response.'
    let medicareCodeValue = normalizeInputValue(event.target.elements.medicareCode.value || '')
    let medicareDOBValue = normalizeInputValue(event.target.elements.medicareDOB.value || '')
    let zipCodeValue = normalizeInputValue(event.target.elements.zipCode.value || '')
    if (
      medicareCodeValue.length === 11 &&
      medicareDOBValue.length === 8 &&
      zipCodeValue.length === 5
    ) {
      errorText = ''
      submitFindMyPlan({
        medicare_card_number: medicareCodeValue,
        date_of_birth: medicareDOBValue,
        zip_code: zipCodeValue,
      })
    }
    setFormError(errorText)
  }
  return (
    <>
      <div className={formModalStyles.modalBody}>
        <Grid container className={formModalStyles.formWrapper}>
          <Grid item xs={12}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={formModalStyles.topDescription}
            >
              We are having some trouble finding your plan. Please tell us a little bit about
              yourself and we can search for your plan!
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              component="form"
              noValidate
              action="/"
              onSubmit={(e) => {
                handleFindMyPlan(e)
              }}
            >
              <FormControl className={formModalStyles.formControl}>
                <InputLabel shrink htmlFor="medicareCode">
                  Medicare Card Number
                </InputLabel>
                <InputMask mask="9a*9-a*9-aa99" placeholder="____-___-____" name="medicareCode" />
              </FormControl>

              <FormControl className={formModalStyles.formControl}>
                <InputLabel shrink htmlFor="medicareDOB">
                  Date Of Birth
                </InputLabel>
                <InputMask mask="99-99-9999" placeholder="MM-DD-YYYY" name="medicareDOB" />
              </FormControl>

              <FormControl className={formModalStyles.formControl}>
                <InputLabel shrink htmlFor="zipCode">
                  Zip Code
                </InputLabel>
                <InputMask mask="99999" placeholder="_____" name="zipCode" />
              </FormControl>
              <FormHelperText className={formModalStyles.formConsolidateError}>
                {formError}
              </FormHelperText>
              <button className={formModalStyles.modalFormButton}>
                <span>Find My Plan</span>
              </button>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              id="modal-sample-image-title"
              variant="h6"
              component="h2"
              className={formModalStyles.sampleImageTitle}
            >
              Sample Card
            </Typography>
            <img
              className={formModalStyles.sampleImage}
              src={sampleMedicare}
              alt="sample medicare"
            />
            <Typography
              id="modal-sample-image-footer"
              component="div"
              className={formModalStyles.sampleImageFooter}
            >
              Medicare Card Number
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default MedicareFormModal
