import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import { Container } from '@mui/material'

import styles from './NeedHelp.module.scss'

const NeedHelp = () => {
  return (
    <Container align="center" className={styles.contactUs}>
      <h1>Need help from a human?</h1>
      <p>Contact us via contact form</p>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contact"
        className={styles.contactButton}
      >
        Contact Us
      </Button>
    </Container>
  )
}

export default NeedHelp
