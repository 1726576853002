import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Grid, MenuItem, Select, TextField } from '@mui/material'

import { stateOptions } from 'shared/constants/states'

import UpdateButton from './UpdateButton'
import { put } from '../../utils/requester'

const EditAddress = ({ defaultValue, personId, contactMethodId }) => {
  const [formState, setFormState] = useState(defaultValue)
  const navigate = useNavigate()

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const updateAddress = () => {
    const endpoint = `/member_portal/api/v1/people/${personId}/contact_methods/${contactMethodId}`
    return put(endpoint, formState).then(() => {
      navigate('/profile')
    })
  }

  return (
    <Grid container direction={'column'} spacing={4} justify="center">
      <Grid item>
        <TextField
          placeholder="Address"
          id="address"
          name="address"
          label="Address"
          variant="outlined"
          defaultValue={formState.address}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          placeholder="Address Line 2"
          label="Address Line 2"
          id="address-2"
          name="address_2"
          variant="outlined"
          defaultValue={formState.address_2}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          placeholder="City"
          label="City"
          id="city"
          name="city"
          variant="outlined"
          defaultValue={formState.city}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Select
          id="state"
          name="state"
          value={formState.state}
          defaultValue={formState.state}
          label="State"
          variant="outlined"
          onChange={handleChange}
          fullWidth
        >
          {stateOptions.map((state) => (
            <MenuItem value={state.short} key={state.short}>
              {state.short}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <TextField
          placeholder="Zip"
          label="Zip"
          id="zip"
          name="zip"
          variant="outlined"
          defaultValue={formState.zip}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <UpdateButton handler={updateAddress} />
    </Grid>
  )
}

export default EditAddress
