import * as ReactDOM from 'react-dom'
import { StrictMode, useEffect, useState } from 'react'

import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { DataDogRUMProvider } from '@assuranceiq/react-components'
import { Loading, useDataDogRUM } from '@assuranceiq/react-components'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_RESPONSE_TYPE,
  AUTH0_SCOPE,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_VERSION,
} from './utils/environment-variables'
import Portal from './Portal'

const AppWrapper = () => {
  const { error, getAccessTokenSilently, user, isAuthenticated } = useAuth0()
  const { addRumGlobalContext } = useDataDogRUM()
  const [isLoading, setIsLoading] = useState(
    !isAuthenticated || !sessionStorage.getItem('accessToken'),
  )

  useEffect(() => {
    getAccessTokenSilently({
      audience: AUTH0_AUDIENCE,
    }).then((accessToken) => {
      sessionStorage.setItem('accessToken', accessToken)
      setIsLoading(false)
    })
  }, [getAccessTokenSilently])

  useEffect(() => {
    addRumGlobalContext('user', {
      email: user.email,
      name: user.name,
    })
  }, [user, addRumGlobalContext])

  if (isLoading) {
    return <Loading fullscreen />
  }

  if (error) {
    console.error('Auth failed', error)
    return
  }

  return <Portal></Portal>
}

const AppWrapperWithAuth = withAuthenticationRequired(AppWrapper, {
  // returnTo: window.location.pathname,
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: function HOC() {
    // show a loading spinner while the user waits to be redirected to the login page
    return <Loading fullscreen />
  },
})

const onRedirectCallback = () => {
  // TODO once codebase is merged - replace with Auth0WithReactRouterIntegrationProvider from below
}

// TODO use after codebase merge - it will correctly redirect after auth
// export const Auth0WithReactRouterIntegrationProvider: FC = ({ children }) => {
//   const navigate = useNavigate()
//
//   return (
//     <Auth0Provider
//       domain={AUTH0_DOMAIN}
//       clientId={AUTH0_CLIENT_ID}
//       redirectUri={window.document.location.origin}
//       responseType={AUTH0_RESPONSE_TYPE}
//       onRedirectCallback={(appState) => {
//         const path = (appState?.returnTo || window.location.href).replace(
//           window.location.origin,
//           '',
//         )
//         navigate(path)
//       }}
//       // HACK: Can’t use cookie auth for local development, so we use refresh tokens and localstorage instead
//       useRefreshTokens={process.env.NODE_ENV === 'development'}
//       cacheLocation={process.env.NODE_ENV === 'development' ? 'localstorage' : 'memory'}
//     >
//       {children}
//     </Auth0Provider>
//   )
// }

ReactDOM.render(
  <StrictMode>
    <DataDogRUMProvider
      applicationId={DD_APPLICATION_ID}
      clientToken={DD_CLIENT_TOKEN}
      service={DD_SERVICE}
      env={DD_ENV}
      sampleRate={DD_SAMPLE_RATE}
      trackInteractions={true}
      version={DD_VERSION}
    >
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        audience={AUTH0_AUDIENCE}
        responseType={AUTH0_RESPONSE_TYPE}
        scope={AUTH0_SCOPE}
        redirectUri={window.document.location.origin}
        onRedirectCallback={onRedirectCallback}
        // HACK: Can’t use cookie auth for local development, so we use refresh tokens and localstorage instead
        useRefreshTokens={process.env.NODE_ENV === 'development'}
        cacheLocation={process.env.NODE_ENV === 'development' ? 'localstorage' : 'memory'}
        post
      >
        <AppWrapperWithAuth />
      </Auth0Provider>
    </DataDogRUMProvider>
  </StrictMode>,
  document.getElementById('root'),
)
