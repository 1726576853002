import { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'

import ToDoTask from './ToDoTask'
import { post } from '../../utils/requester'

import styles from './ToDoTasks.module.scss'

const ToDoTasks = ({ application, medicareData }) => {
  const [todoTasksList, setTodoTasksList] = useState([])
  const {
    to_dos_title = '',
    to_dos_descriptions = [],
    to_do_tasks,
    de_policy_id,
    action_required = false,
  } = application.attributes

  useEffect(() => {
    const loadTodosTasks = () => {
      post(`/member_portal/api/v1/applications/${de_policy_id}/customer_to_dos`).then((result) => {
        setTodoTasksList(result?.data || [])
      })
    }
    !isEmpty(de_policy_id) && !isEmpty(to_do_tasks) && loadTodosTasks()
  }, [to_do_tasks, de_policy_id])

  return (
    <>
      {to_dos_title && !isEmpty(to_dos_descriptions) && (
        <div>
          <div className={styles.headerWrapper}>
            <div className={styles.headerText}>Status: {to_dos_title}</div>
            {action_required === true && (
              <div className={styles.headerActionRequired}>
                <span>ACTION REQUIRED</span>
              </div>
            )}
          </div>
          {to_dos_descriptions.map((to_dos_description) => (
            <div className={styles.description} key={to_dos_description}>
              {to_dos_description}
            </div>
          ))}
        </div>
      )}

      {todoTasksList.length > 0 && (
        <div>
          <div className={styles.headerText}>Tasks:</div>
          {todoTasksList.map((row) => (
            <ToDoTask initialTaskDetail={row} medicareData={medicareData} key={row.id} />
          ))}
        </div>
      )}
    </>
  )
}

export default ToDoTasks
