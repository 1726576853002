import axios from 'axios'

import { API_ENDPOINT } from './environment-variables'

let api

export function getApi() {
  if (api) {
    return api
  }
  const token = sessionStorage.getItem('accessToken')

  if (!token) {
    throw new Error('accessToken does not exist')
  }

  return (api = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
    },
  }))
}
