import { Link } from 'react-router-dom'

import { Divider, ListItem, ListItemText } from '@mui/material'

import { capitalize, values as lodashValues } from 'lodash'

const ProfileListItem = ({
  fieldTitle,
  fieldName,
  fieldValue,
  disableLink,
  displayFormat,
  lastItem,
  personId,
  contactMethodId,
}) => {
  const displayValue = () => {
    switch (typeof fieldValue) {
      case 'string':
        return capitalize(fieldValue)
      case 'object':
        return objectSecondaryText(fieldValue)
      default:
        return defaultSecondaryText()
    }
  }

  const objectSecondaryText = (object) => {
    if (object) {
      if (displayFormat === 'address') return addressSecondaryText(object)

      return lodashValues(object).join(' ')
    } else {
      return defaultSecondaryText()
    }
  }

  const addressSecondaryText = (addressObject) => {
    return (
      <div>
        <div>{addressObject.address}</div>
        <div>{addressObject.address_2}</div>
        <div>
          {addressObject.city}, {addressObject.state} {addressObject.zip}
        </div>
      </div>
    )
  }

  const defaultSecondaryText = () => {
    return 'Add your ' + fieldName.replaceAll('_', ' ')
  }

  return (
    <>
      <ListItem
        button
        component={Link}
        to={`/profile/edit/${fieldName}`}
        state={{ fieldName, fieldTitle, fieldValue, personId, contactMethodId }}
        style={{ pointerEvents: disableLink ? 'none' : 'inherit' }}
      >
        <ListItemText
          secondaryTypographyProps={{ component: 'div' }}
          primary={fieldTitle}
          secondary={displayValue()}
        />
        <i className={disableLink ? '' : 'fa fa-angle-right'} />
      </ListItem>
      {!lastItem && <Divider />}
    </>
  )
}

export default ProfileListItem
