import xss from 'xss'

const SanitizedHTML = ({ dirtyHtml }) => {
  const whiteListOptions = { whiteList: { span: [], a: ['href'] } }
  const sanitize = (dirty) => ({
    __html: xss(dirty, whiteListOptions),
  })

  return <span dangerouslySetInnerHTML={sanitize(dirtyHtml)} />
}

export default SanitizedHTML
