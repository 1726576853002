import { useEffect, useState } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { List } from '@mui/material'

import ContactPreference from '../ContactPreference'
import { getJson } from '../../../utils/requester'

import styles from './ContactPreferences.module.scss'

const ContactPreferences = () => {
  const [people, setPeople] = useState([])

  useEffect(() => {
    const loadProfile = () => {
      getJson('/member_portal/api/v1/people').then((result) => {
        setPeople(result.data)
      })
    }
    loadProfile()
  }, [])

  return (
    <div className={styles.card}>
      <h2 className={styles.subheader}>Contact Preferences</h2>
      <p className={styles.subheader}>
        We’ll keep this in mind when contacting you about your policy options.
      </p>

      <List>
        {people.map((person) => {
          return (
            <ContactPreference
              personId={person.id}
              currentPreference={person.attributes.contact_preferences}
              key={person.id}
            />
          )
        })}
      </List>

      <div className={styles.notice}>
        <InfoOutlinedIcon fontSize="small" className={styles.infoIcon} />
        <div className={styles.noticeText}>
          <span className={styles.bold}>Please note: </span>
          <span>
            we may still contact you regarding your policy options using any of the communication
            channels, selected or not.
          </span>
        </div>
      </div>
    </div>
  )
}

export default ContactPreferences
