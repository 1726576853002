import SidebarNavItem from '../SidebarNavItem/SidebarNavItem'
import SidebarTopNavs from '../SidebarTopNavs/SidebarTopNavs'

import styles from './SideBar.module.scss'

const SideBar = ({ topNavs, otherNavs, onClick }) => {
  return (
    <nav className={styles.wrapper}>
      <SidebarTopNavs navItems={topNavs} onClick={onClick} />

      {otherNavs.map((row) => (
        <SidebarNavItem navItem={row} key={row.name} />
      ))}
    </nav>
  )
}

export default SideBar
