import { Route, Routes } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Box, useMediaQuery } from '@mui/material'

import { map } from 'lodash'

import ContactUs from './components/ContactUs/ContactUs'
import EditPassword from './components/Settings/EditPassword/EditPassword'
import EditProfileField from './components/Profile/EditProfileField/EditProfileField'
import Faq from './components/HelpCenter/Faq/Faq'
import Footer from './components/Footer/Footer'
import HelpCenter from './components/HelpCenter/HelpCenter'
import Home from './components/Home/Home'
import InsuranceInfo from './components/InsuranceInfo/InsuranceInfo'
import NavigationBar from './components/NavigationBar/NavigationBar'
import NotFound from './components/NotFound/NotFound'
import Profile from './components/Profile/Profile'
import Settings from './components/Settings/Settings'
import SideBar from './components/SideBar/SideBar'
import { getJson } from './utils/requester'
import { theme } from './styles/theme'

import styles from './styles.module.scss'

const createData = (name, route, children, icon) => {
  return { name, route, children, icon }
}

export const topNavs = [
  createData('Overview', '/', []),
  createData('Profile', 'profile', []),
  createData('Settings', 'settings', []),
  createData('Help Center', 'help_center', []),
  createData('Contact Us', 'contact', []),
]

export const otherNavs = [
  // createData('YOUR PROTECTION', '', [createData('Medicare', '', [], 'medicare')]),
  createData('Explore', '', [
    createData('Auto Insurance', '/auto_insurance', []),
    createData('Health Insurance', '/health_insurance', []),
    createData('Home Insurance', '/home_insurance', []),
    createData('Life Insurance', '/life_insurance', []),
  ]),
]

const App = () => {
  const [primaryPerson, setPrimaryPerson] = useState({})
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    getJson('/member_portal/api/v1/people/primary').then((result) => {
      setPrimaryPerson(result.data.attributes)
    })
  }, [])

  useEffect(() => {
    getJson('/member_portal/api/v1/leads').then((result) => {
      const leadsAttributes = map(result.data, (properties) => {
        return properties.attributes
      })
      // Need to figure out how we are dealing with gon data. gon token used
      // in profile/edit/drugs profile/edit/doctors where we are using
      // medicare react components.
      // The token is distinct to a direct lead. Multiple leads will break this.
      window.gon = { session: { token: leadsAttributes[0].token } }
    })
  }, [])

  return (
    <>
      <NavigationBar primaryPerson={primaryPerson} topNavs={topNavs} otherNavs={otherNavs} />
      <Box className={styles.body}>
        {isDesktop && <SideBar topNavs={topNavs} otherNavs={otherNavs} />}
        <main className={styles.main}>
          <Routes>
            <Route index={true} element={<Home primaryPerson={primaryPerson} />} />
            <Route path="profile" element={<Profile />}>
              <Route path="edit/:field" element={<EditProfileField />} />
            </Route>
            <Route path="settings" element={<Settings />}>
              <Route path="edit/password" element={<EditPassword />} />
            </Route>
            <Route path="contact" element={<ContactUs />} />
            <Route path="help_center" element={<HelpCenter />} />
            <Route path="help_center/faq" element={<Faq />} />
            <Route path="auto_insurance" element={<InsuranceInfo loi="auto" />} />
            <Route path="health_insurance" element={<InsuranceInfo loi="health" />} />
            <Route path="home_insurance" element={<InsuranceInfo loi="home" />} />
            <Route path="life_insurance" element={<InsuranceInfo loi="life" />} />
            <Route path="*" component={<NotFound />} />
          </Routes>
          <Footer />
        </main>
      </Box>
    </>
  )
}

export default App
