import { useState } from 'react'

import { Button } from '@mui/material/'

import styles from './MedicareApplication.module.scss'

const LinkPolicyComponent = ({ progressDetail, handleOpen }) => {
  const [buttonClicked, setButtonClicked] = useState(false)
  const application_progress =
    progressDetail.progress_status.find((obj) => obj.status_name === 'application') || {}

  if (application_progress.progress && application_progress.progress === 100) {
    return null
  }
  if (buttonClicked) {
    return null
  }
  return (
    <div className={styles.alreadyPurchased}>
      Already purchased a plan from us?{' '}
      <Button
        className={styles.clickHereButton}
        onClick={function () {
          setButtonClicked(true)
          handleOpen()
        }}
      >
        Click here
      </Button>
    </div>
  )
}

export default LinkPolicyComponent
