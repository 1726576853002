import { useState } from 'react'

import { Box, Drawer, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'

import SideBar from '../SideBar/SideBar'

import styles from './DrawerMenu.module.scss'

const DrawerMenu = ({ topNavs, otherNavs }) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const handleClick = () => setOpenDrawer(false)
  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box className={styles.close}>
          <IconButton onClick={() => setOpenDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <SideBar topNavs={topNavs} otherNavs={otherNavs} isMobile={true} onClick={handleClick} />
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={styles.menuIcon}>
        <MenuIcon />
      </IconButton>
    </>
  )
}
export default DrawerMenu
