import GreenCheckIcon from 'images/member_portal/green_check_icon.svg'

import { capitalizeFirstLetter } from '../../utils'

import styles from './ProgressCircleItem.module.scss'

const ProgressCircleItem = ({ displayNumber, displayName, progressPercent }) => {
  const showWhiteCircle = progressPercent === 0
  const showCheckIcon = progressPercent === 100
  const boldText = progressPercent > 0 && progressPercent < 100

  return (
    <div className={styles.wrapper}>
      <div className={styles.circleWithProgress}>
        <div className={showWhiteCircle ? styles.whiteCircle : styles.greenCircle}>
          {showCheckIcon ? (
            <img height="24px" width="24px" src={GreenCheckIcon} alt={displayName} />
          ) : (
            displayNumber
          )}
        </div>
        {displayNumber !== 4 && <span className={styles.bar} />}
      </div>

      <span className={boldText ? styles.boldTitle : styles.title}>
        {capitalizeFirstLetter(displayName)}
      </span>
    </div>
  )
}

export default ProgressCircleItem
