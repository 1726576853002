import { createTheme } from '@mui/material/styles'

import Colors from './config/_colors.scss'
import Typography from './config/_typography.scss'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: Colors['blue-100'],
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
      htmlFontSize: Typography['font-size-base'],
      fontWeight: Typography['font-weight-bold'],
    },
  },
})
