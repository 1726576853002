import { useState } from 'react'

import UploadIcon from 'images/member_portal/upload.svg'
import clsx from 'clsx'

import UploadFileProgressComponent from './UploadFileProgressComponent/UploadFileProgressComponent'
import { uploadFileWithProgressBar } from '../../../utils/requester'
import useDragAndDrop from '../../../hooks/useDragAndDrop'

import styles from './DragDropFileInputComponent.module.scss'

const VALID_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf']
const FILE_NAME = 'file'
const MAX_UPLOAD_FILE_SIZE_MB = 10
const UPLOAD_TYPE_ERROR_MESSAGE = 'Please provide an image/pdf file to upload!'
const UPLOAD_SIZE_ERROR_MESSAGE = `Please provide an image/pdf file upto ${MAX_UPLOAD_FILE_SIZE_MB}MB only!`

const DragDropFileInputComponent = ({ taskDetail, onFileUpload }) => {
  const taskId = taskDetail?.id
  const { dragOver, setDragOver, onDragOver, onDragLeave, fileDropError, setFileDropError } =
    useDragAndDrop()

  const [file, setFile] = useState()
  const [progress, setProgress] = useState(0)
  const validFileToUpload = (file) => {
    return VALID_FILE_TYPES.includes(file.type)
  }

  const fileMaxSizeExceed = (file) => {
    return file.size / 1024 / 1024 > MAX_UPLOAD_FILE_SIZE_MB
  }

  const onDrop = (e) => {
    e.preventDefault()

    setDragOver(false)

    const selectedFile = e?.dataTransfer?.files[0]

    if (!validFileToUpload(selectedFile)) {
      return setFileDropError(UPLOAD_TYPE_ERROR_MESSAGE)
    }

    if (fileMaxSizeExceed(selectedFile)) {
      return setFileDropError(UPLOAD_SIZE_ERROR_MESSAGE)
    }

    setFileAndUpload(selectedFile)
  }

  const fileSelect = (e) => {
    let selectedFile = e.target.files[0]

    if (!validFileToUpload(selectedFile)) {
      return setFileDropError(UPLOAD_TYPE_ERROR_MESSAGE)
    }

    if (fileMaxSizeExceed(selectedFile)) {
      return setFileDropError(UPLOAD_SIZE_ERROR_MESSAGE)
    }

    setFileAndUpload(selectedFile)
    setFileDropError('')
  }

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      if (percent < 100) {
        setProgress(percent)
      }
    },
  }

  const setFileAndUpload = (selectedFile) => {
    setFile(selectedFile)
    const loadTodosTasks = () => {
      const payload = new FormData()
      payload.append(FILE_NAME, selectedFile)
      uploadFileWithProgressBar(
        `/member_portal/api/v1/customer_to_do_tasks/${taskId}/upload`,
        payload,
        options,
      )
        .then((result) => {
          setFile('')
          onFileUpload(result)
        })
        .catch(() => {
          setFile('')
          setFileDropError('Sorry, file upload failed.')
        })
    }
    loadTodosTasks()
  }

  return (
    <div className={styles.container}>
      <form method="POST" encType="multipart/form-data">
        {fileDropError && <span className={styles.fileDropError}>{fileDropError}</span>}
        <label
          htmlFor={`${FILE_NAME}-${taskId}`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          className={styles.labelUpload}
          style={{ border: `${dragOver ? '3px dashed yellowgreen' : ''}` }}
        >
          {file && <UploadFileProgressComponent file={file} progressPercentage={progress} />}
          {!file && (
            <>
              <div className={styles.mobileContent}>
                <h1>Click here to upload or take a photo of your document.</h1>
                <div className={styles.mobileUploadButton}>
                  <span>Upload / Take Photo</span>
                </div>
              </div>
              <div className={styles.desktopContent}>
                <img src={UploadIcon} alt="Upload document" height="32px" width="32px" />
                <h1 className={clsx(dragOver && styles.yellowgreen, styles.noMargin)}>
                  Drag and drop file here
                </h1>
                <h1 className={styles.noTopPadding}>or</h1>
                <div className={styles.browseButton}>
                  <span>Browse</span>
                </div>
              </div>
              <h1 className={styles.fileValidationInfo}>
                Max {MAX_UPLOAD_FILE_SIZE_MB}MB; JPG, JPEG, PNG, or PDF only
              </h1>
            </>
          )}
        </label>
        <input
          type="file"
          name={FILE_NAME}
          id={`${FILE_NAME}-${taskId}`}
          onChange={fileSelect}
          accept={VALID_FILE_TYPES.join(',')}
          className={styles.inputFile}
        />
      </form>
    </div>
  )
}

export default DragDropFileInputComponent
