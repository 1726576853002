import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'

import { Breadcrumbs, List, ListItem, ListItemText } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { find } from 'lodash'

import AccordionItems from '../AccordionItems'
import BannerPageLayout from '../../Layouts/BannerPageLayout/BannerPageLayout'
import NeedHelp from '../NeedHelp'

import styles from './Faq.module.scss'

const topics = [
  { value: 'medicare', displayName: 'Medicare' },
  { value: 'applications', displayName: 'Applications' },
  { value: 'accountProfile', displayName: 'Account Profile' },
  { value: 'carrierCards', displayName: 'Carrier Cards' },
  { value: 'costs', displayName: 'Costs' },
  { value: 'physicians', displayName: 'Physicians & Rx' },
]

const items = {
  medicare: [
    {
      summary: 'Am I automatically enrolled in Medicare?',
      detail:
        'People living in the United States and U.S. Territories (except Puerto Rico) who are already collecting Social Security—either disability or retirement—are automatically enrolled into Part A and Part B when they’re first eligible. These people will get a packet of information a few months before they turn 65 or receive their 25th month of Social Security Disability or Railroad Retirement Board (RRB) benefits. At that time, they can choose to keep or decline Part B, but can’t decline Part A unless they withdraw their original application for Social Security and pay back all Social Security cash benefits. You will not be automatically enrolled in a Medicare Advantage or other type of plan.',
    },
    {
      summary: 'When can I enroll in Medicare?',
      detail:
        'You have a 7 month period to enroll; 3 months before your 65th birthday, your birthday month, and the 3 months following your birthday. If you miss this period, you may be responsible for a permanent surcharge on your Medicare Part B and/or Part D coverage.',
    },
    {
      summary: 'What is a Medicare Supplement plan?',
      detail:
        'Medicare Supplemental plans are sometimes referred to as Medicare Supplement plans. You must have Medicare parts A&B  to enroll in a Medicare Supplement plan. Medicare Supplement plans can help you pay for costs that Medicare Part A and Part B don’t cover such as deductible, copay or co-insurance.  You cannot have a Medicare Supplement plan if you have a Medicare Advantage Plan.',
    },
    {
      summary: 'When can I buy a Medicare Supplement Plan?',
      detail:
        'You have a 6 month open enrollment period that starts the day you have Medicare Part B and are 65 or older. This is a one time period and can’t be extended. At the end of this period, you may not be able to buy Medicare Supplement coverage, or if you are eligible, you may pay a higher rate since you are out of your guaranteed issue period and insurers may factor in your health conditions. If you are under 65 and have Medicare due to a disability or other qualifying condition you may be eligible to enroll in a Medigap plan if you reside in one of the 27 states that require Medicare Supplement companies to sell policies to people under the age of 65 who qualify for Medicare due to a disability or other qualifying medical condition. Other restrictions may also apply. Outside of specific states, companies are not required to offer MediGap policies to those under age 65.',
    },
    {
      summary: 'Can I have both Medicare and Medicaid?',
      detail:
        'You can enroll in Medicare even if you are already on Medicaid. You may be dual eligible if you meet certain requirements. If you are not dual eligible, you may be able to qualify for federally funded financial assistance to help with costs. You will need to check with your state’s Medicaid office to find out if you qualify.',
    },
    {
      summary:
        'What is the difference between Original Medicare (Parts A & B) and Medicare Advantage Plans (Part C)?',
      detail:
        'Original Medicare (Parts A & B); Part A typically covers hospitalization, skilled nursing and similar services. Part B typically covers doctor visits, some preventative care, and outpatient visits. Medicare Advantage covers both Part A and Part B  but may also include prescription drug coverage and other benefits such as vision, dental or hearing. The type of benefits and coverage amounts vary by plan.',
    },
    {
      summary: 'Can I enroll my husband or wife in Medicare?',
      detail:
        'No. Unless you have an active Power of Attorney or other legally binding document that gives you permission to act on behalf of the beneficiary (enrollee), he/she must do the enrollment on their own behalf.',
    },
    {
      summary: 'Do I have to enroll in Medicare if I am still working at age 65?',
      detail:
        "It depends. If you are are working (or covered through a working spouse), you don’t have to sign up for Medicare at age 65. You can keep your employer sponsored coverage until a) you (or your spouse) stop working or b) you (or your spouse) lose coverage. However, if you are self employed or work for a company where health coverage is not available to everyone, you need to be sure your coverage meets the IRS' definition of a group health plan. If not, sign up for Medicare when you turn 65 to avoid potential penalties on your Medicare Part B. Also, if your employer has less than 20 employees, you may want to sign up for Medicare to avoid a potential gap in coverage if your employer discontinues coverage. If you have COBRA, you may want to sign up for Medicare to avoid gaps in coverage, penalties or other disadvantages. Just know, your COBRA coverage may terminate when you enroll in Medicare.",
    },
    {
      summary:
        'Do I have to be on the same Medicare Advantage plan as my spouse or others in my household?',
      detail:
        'No. Everyone is entitled to choose his/her Medicare coverage within the Annual Enrollment Period or other qualifying periods.',
    },
    {
      summary: 'Am I eligible for Medicare Part B give back?',
      detail:
        'You will typically need to meet the following criteria to be eligible: 1) Live within a service area where this benefit is available. 2) Have both Medicare Parts A & B. 3) Be responsible for your Part B premium. This means you can’t be on Medicaid or receiving assistance in paying your Medicare Part B premium and still receive the give back benefit.',
    },
  ],
  applications: [
    {
      summary: 'Can I change my Medicare coverage?',
      detail:
        'You can change your Medicare coverage annually during the Annual Enrollment Period (AEP) which occurs each year from October 15 through December 7. Outside of this period, you will either need to be new to Medicare or meet specific criteria known as an election or special election. If you are currently enrolled in a Medicare Advantage plan, you can make a one-time change during MA OEP is January 1 - March 31, annually.',
    },
    {
      summary: 'Do I have to enroll in my Medicare plan every year?',
      detail:
        'No. If you are happy with your current coverage and you have not been notified by your insurance provider that your plan is discontinued, you do not need to re-enroll. Your coverage will be automatically renewed. If you received an Annual Notice of Change, and your coverage and/or benefits are changing, you also do not need to do anything to accept the changes. It will be automatically renewed if you do not enroll in a different plan or type of coverage.',
    },
    {
      summary: 'What happens after I submit my application?',
      detail:
        'Applications are reviewed by CMS/Carriers for accuracy and eligibility. On average, it takes 7-10 days to complete this process. Once reviewed you will be notified of the effective date of your policy or you will receive a notification your application was withdrawn or denied. You may also be required to submit additional documentation such as a valid Medicare ID number or proof of Medicare Part A or B. If you need to submit additional documentation or information, you will be contact by Assurance and/or the insurance provider with whom you have applied. This documentation/information will need to be received in order for you to complete the application process.',
    },
    {
      summary: 'How do I check the status of my application?',
      detail:
        '<span>If you have submitted an application through Assurance, you can contact our Customer Care department at 833-922-2309 or you can login to our portal <a href="/member_portal_app">here</a>.</span>',
    },
    {
      summary: 'What does it mean if my application status is "pending" or "sent to carrier"?',
      detail:
        'This indicates your application has been sent or received by the Carrier and is the review process. Please see "what happens after I submit my application?" for more details.',
    },
    {
      summary: 'Can I withdraw my application?',
      detail:
        'Yes, you can change your mind about your coverage options. You can contact our Customer Care department, your insurance provider or CMS. It is important to be aware that you may not be able to enroll in other coverage without an election depending upon the time of year or other circumstances. Our Customer Care team can help you understand your options and any challenges you may encounter when withdrawing/cancelling an application.',
    },
    {
      summary: 'Will my application be automatically approved?',
      detail:
        'In most cases, your application will be reviewed. If it is approved and you meet the necessary criteria for coverage you selected, you will receive notification from Assurance as well as the insurance provider. You will be notified by the insurance provider if your coverage selection is denied or you are ineligible.',
    },
    {
      summary: 'What if I made a mistake or need to correct something on my application?',
      detail:
        'You should contact Customer Care immediately to determine your options. In some cases, a new application will be required and in other cases, we can update information with you and the insurance provider.',
    },
    {
      summary: 'I moved. What do I need to do?',
      detail:
        'Some Medicare plans are limited to specific geographical areas. If you have moved, you may need to submit a new application. You can contact Customer Care for assistance.',
    },
    {
      summary: 'I received a request to provide some additional information. How do I send it?',
      detail: 'You can send it via email to enrollmentmc@assurance.com.',
    },
  ],
  accountProfile: [
    {
      summary: 'How do I change my password?',
      detail:
        'You can change your password by signing into your account and navigating to the "Settings" section.',
    },
    {
      summary: 'How do I edit my profile?',
      detail:
        'You can edit your profile in the "Profile" section after you’re logged in. Any updates to your profile will not be automatically shared with our carriers.',
    },
    {
      summary: 'Can a licensed insurance agent view my profile information?',
      detail:
        'Yes - an agent can view your profile information. Your information is stored on our secured servers and accessible to only licensed and trained Medicare agents.',
    },
    {
      summary: 'Do I have to be worried if I forgot to sign out?',
      detail:
        'No. If you forgot to sign out, we will automatically end the session after a few minutes.',
    },
  ],
  carrierCards: [
    {
      summary: 'How do I get new insurance ID cards?',
      detail:
        'You can typically get new id cards by requesting them from your insurance provider. Most providers have a website you can access to make this request, or you can call our Customer Care department or the Carrier directly.',
    },
  ],
  costs: [
    {
      summary:
        'I have a HSA (health savings account). Is there a penalty for continuing to contribute?',
      detail:
        'Health Savings Accounts help offset the cost of deductible or medical expenses that may not be covered by your health plan such as a copay. If you do not stop working at age 65, you can continue to contribute to your HSA. Just remember to stop contributing 6 months prior to enrolling in Medicare to avoid potential penalties. Consult your tax professional to understand your specific circumstances.',
    },
    {
      summary: 'What is the difference between a copay and coinsurance?',
      detail:
        'A copay is the set rate you pay for services and products like prescription drugs. Coinsurance is the percentage of costs you pay after you have met your deductible.',
    },
  ],
  physicians: [
    {
      summary: 'Can I change my primary care physician if I have an HMO?',
      detail:
        'Usually. Contact our Customer Care department or your health insurance provider to make sure the physician you would like to use is accepting your plan and new patients.',
    },
    {
      summary: 'How do I know if my prescriptions are covered?',
      detail:
        'Your Summary of Benefits will include a Formulary where you can see if your prescriptions are covered. You can also contact our Customer Care department for assistance. Many plans place drugs into different levels, called “tiers,” on their formularies. Drugs in each tier have a different cost. For example, a drug in a lower tier will generally cost you less than a drug in a higher tier.',
    },
    {
      summary:
        'What do I do if my Insurance ID Card does not have my primary care doctor listed as the physician?',
      detail:
        'If your insurance ID card does not have your primary care doctor listed then please contact our Customer Care for help. We can assist you in confirming your primary care provider accepts the plan in which you are enrolled or help you make changes if necessary.',
    },
  ],
}

const Faq = () => {
  const { state: params } = useLocation()
  const [selectedTopic, setSelectedTopic] = useState(
    find(topics, { value: params?.faqItem }) || topics[0],
  )

  const itemsForTopic = () => {
    return items[selectedTopic.value]
  }

  return (
    <>
      <BannerPageLayout
        banner="Help Center"
        gridContainerProps={{ className: styles.main }}
        gridItemProps={{ sm: 12, lg: 10, xl: 8 }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className={styles.breadCrumbs}
        >
          <Link to="/help_center" className={styles.breadCrumbsRoot}>
            Help Center Home
          </Link>
          <p>{selectedTopic.displayName}</p>
        </Breadcrumbs>
        <div className={styles.faqWrapper}>
          <div className={styles.faqSideBar}>
            <h2>Select a topic:</h2>
            <List>
              {topics.map((topic) => (
                <ListItem key={topic.displayName}>
                  <ListItemText
                    primary={topic.displayName}
                    onClick={() => setSelectedTopic(topic)}
                    className={
                      topic === selectedTopic ? styles.selectedSideBarTopic : styles.sideBarTopic
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
          <div className={styles.accordion}>
            <h1 className={styles.accordionHeader}>{selectedTopic.displayName}</h1>
            <AccordionItems items={itemsForTopic()} />
          </div>
        </div>

        <NeedHelp />
      </BannerPageLayout>
    </>
  )
}

export default Faq
