import { useEffect, useState } from 'react'

import MedicareApplication from '../MedicareApplication/MedicareApplication'
import { getJson } from '../../utils/requester'

import styles from '../MedicareApplications/MedicareApplications.module.scss'

const MedicareApplications = ({ applications }) => {
  const [medicareData, setMedicareData] = useState({})
  const { enrollment_name, days_remaining } = medicareData.enrollment_data || {}
  useEffect(() => {
    getJson('/member_portal/api/v1/applications/medicare/medicare_data').then((result) => {
      setMedicareData(result.data.attributes)
    })
  }, [])

  return (
    <>
      {days_remaining > 0 && enrollment_name !== '' && (
        <div className={styles.remainingDaysBox}>
          {enrollment_name} is here! It ends in {days_remaining} days.
        </div>
      )}
      {applications.map((application) => (
        <MedicareApplication
          key={application.attributes.lead_id}
          application={application}
          medicareData={medicareData}
        />
      ))}
    </>
  )
}
export default MedicareApplications
