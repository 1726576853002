import Grid from '@mui/material/Grid'

import styles from './Article.module.scss'

const Article = ({ article: { header, url, image_url } }) => {
  return (
    <Grid item xs={12} sm={6} md={4} className={styles.wrapper}>
      <a className={styles.title} href={url} target="_blank" rel="noopener noreferrer">
        <img src={image_url} alt="" className={styles.image} />
        {header}
      </a>
    </Grid>
  )
}

export default Article
