import Button from '@mui/material/Button'
import { Grid } from '@mui/material'

const UpdateButton = ({ handler }) => {
  return (
    <Grid item align="center">
      <Button
        fullWidth
        variant="contained"
        id="submit"
        color="primary"
        onClick={handler}
        style={{ borderRadius: 100, backgroundColor: '#007CC2' }}
      >
        Update
      </Button>
    </Grid>
  )
}

export default UpdateButton
