import { CircularProgress } from '@mui/material'

import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.container}>
      <CircularProgress aria-label="loading" size="4rem" />
    </div>
  )
}

export default Loader
