import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Grid, TextField } from '@mui/material'

import UpdateButton from './UpdateButton'
import { put } from '../../utils/requester'

const EditName = ({ defaultValue, personId }) => {
  const [formState, setFormState] = useState(defaultValue)
  const navigate = useNavigate()

  const updateName = () => {
    return put(`/member_portal/api/v1/people/${personId}`, formState).then(() => {
      navigate('/profile')
    })
  }

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Grid container direction={'column'} spacing={4} justify="center">
      <Grid item>
        <TextField
          placeholder="First Name"
          id="first-name"
          name="first_name"
          label="First Name"
          variant="outlined"
          defaultValue={formState.first_name}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          placeholder="Last Name"
          label="Last Name"
          id="last-name"
          name="last_name"
          variant="outlined"
          defaultValue={formState.last_name}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <UpdateButton handler={updateName} />
    </Grid>
  )
}

export default EditName
