import { useState } from 'react'

import { Checkbox, FormControlLabel, FormGroup, ListItem } from '@mui/material'

import { put } from '../../utils/requester'

import styles from './Settings.module.scss'

const ContactPreference = ({ personId, currentPreference }) => {
  const [contactPreference, setContactPreference] = useState(currentPreference || {})

  const togglePreference = (e) => {
    const nextState = {
      ...contactPreference,
      [e.target.name]: e.target.checked,
    }
    setContactPreference(nextState)

    put(`/member_portal/api/v1/people/${personId}`, {
      contact_preferences: nextState,
    })
  }

  return (
    <ListItem>
      <FormGroup className={styles.preferenceWrapper}>
        <FormControlLabel
          className={styles.preferenceOption}
          control={
            <Checkbox
              style={{
                color: '#007CC2',
              }}
              checked={contactPreference.email}
              name="email"
              onChange={togglePreference}
            />
          }
          label="Email"
        />
        <FormControlLabel
          className={styles.preferenceOption}
          control={
            <Checkbox
              style={{
                color: '#007CC2',
              }}
              checked={contactPreference.sms}
              name="sms"
              onChange={togglePreference}
            />
          }
          label="Text"
        />
        <FormControlLabel
          className={styles.preferenceOption}
          control={
            <Checkbox
              style={{
                color: '#007CC2',
              }}
              checked={contactPreference.phone}
              name="phone"
              onChange={togglePreference}
            />
          }
          label="Phone"
        />
      </FormGroup>
    </ListItem>
  )
}

export default ContactPreference
