import { useEffect, useState } from 'react'

import { map } from 'lodash'

import AboutUs from '../AboutUs/AboutUs'
import Applications from '../MemberApplications/MemberApplications'
import Article from '../Article/Article'
import BannerPageLayout from '../Layouts/BannerPageLayout/BannerPageLayout'
import { getJson } from '../../utils/requester'

import styles from './Home.module.scss'

const Home = ({ primaryPerson }) => {
  const [applicationsData, setApplicationsData] = useState({})
  const [articles, setArticles] = useState([])

  useEffect(() => {
    getJson('/member_portal/api/v1/applications').then((result) => {
      if (!result.data) {
        return
      }
      const appData = result.data.reduce(function (appDict, app) {
        let lead_type = app.attributes.lead_type
        appDict[lead_type] = appDict[lead_type] || []
        appDict[lead_type].push(app)
        return appDict
      }, Object.create({}))
      setApplicationsData(appData)
    })
  }, [])

  useEffect(() => {
    getJson('/member_portal/api/v1/articles').then((result) => {
      const articlesAttributes = map(result.data, (properties) => {
        return properties.attributes
      })
      setArticles(articlesAttributes)
    })
  }, [])

  return (
    <>
      <BannerPageLayout
        banner={primaryPerson.first_name ? `Hello, ${primaryPerson.first_name}` : ''}
      >
        <Applications applicationsData={applicationsData} />

        <div className={styles.headerWithoutLogo}>About Assurance</div>
        <AboutUs />

        <div className={styles.headerWithoutLogo}>Articles you may like</div>
        <div className={styles.articlesWrapper}>
          {articles.map((article) => (
            <Article article={article} key={article.header} />
          ))}
        </div>
      </BannerPageLayout>
    </>
  )
}

export default Home
