import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      Assurance IQ represents Medicare Advantage [HMO, PPO, PFFS] and Prescription Drug Plan
      organizations that have a Medicare contract. Enrollment depends on the plan’s contract
      renewal. We do not offer every plan available in your area. Any information we provide is
      limited to those plans we do offer in your area. Not all plans offer all of these benefits.
      Benefits may vary by carrier and location. Limitations and exclusions may apply. Please
      contact Medicare.gov or 1–800–MEDICARE (TTY users should call 1-877-486-2048) 24 hours a day/7
      days a week to get information on all of your options.
    </footer>
  )
}

export default Footer
