import styles from './AboutUs.module.scss'

const createInsuranceLoiData = (name, route) => {
  return { name, route }
}

const allInsuranceLoi = [
  createInsuranceLoiData('Medicare', 'https://assurance.com/medicare-products/'),
  createInsuranceLoiData('Health Insurance', 'https://assurance.com/health-insurance/'),
  createInsuranceLoiData('Life Insurance', 'https://assurance.com/life-insurance/'),
  createInsuranceLoiData('Auto Insurance', 'https://assurance.com/auto-insurance/'),
  createInsuranceLoiData('Home Insurance', 'https://assurance.com/home-insurance/'),
  createInsuranceLoiData(
    'Mortgage',
    'https://assurance.com/top-mortgage-lenders?utm_source=member_portal',
  ),
  createInsuranceLoiData(
    'Personal Loans',
    'https://assurance.com/top-personal-loans-lenders?utm_source=member_portal',
  ),
]

const AboutUs = () => {
  return (
    <>
      <div>
        <div className={styles.topTitle}>
          Our mission is to protect and improve the personal and financial health of all consumers.
          We meet consumers wherever they are in life with custom tailored solutions just for them.
          We empower them to take actions that improve their life and protect the things they care
          about. We offer the following:
        </div>
        <div>
          <ul className={styles.loiWrapper}>
            {allInsuranceLoi.map((loiData) => (
              <li className={styles.loiRow} key={loiData.name}>
                <a href={loiData.route} target="_blank" rel="noopener noreferrer">
                  {loiData.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default AboutUs
