import Grid from '@mui/material/Grid'

import Banner from '../../Banner/Banner'

import styles from './BannerPageLayout.module.scss'

const BannerPageLayout = ({ banner, children, gridContainerProps, gridItemProps }) => {
  return (
    <>
      <Banner text={banner} />
      <Grid container className={styles.root} {...gridContainerProps}>
        <Grid item xs={12} sm={10} lg={8} xl={6} {...gridItemProps}>
          {children}
        </Grid>
      </Grid>
    </>
  )
}

export default BannerPageLayout
