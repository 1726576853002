import { Grid } from '@mui/material'

import CheckIcon from 'images/member_portal/check_icon.svg'
import NotFound from 'images/member_portal/not_found.svg'

const resultData = {
  success: {
    icon: CheckIcon,
    title: 'Congrats - we’ve found your plan!',
    subtitle: 'Please close the window and we will update your information.',
    subtitle2: '',
  },
  fail: {
    icon: NotFound,
    title: 'Oops - we were unable to find your plan!',
    subtitle:
      'Please call our customer care agents at the following number and they will be happy to assist you:',
    subtitle2: '1-833-377-1105 TTY 711    9am - 6pm EST     Monday - Sunday',
  },
}
const ResultModal = ({ resultStatus }) => {
  const result = resultData[resultStatus]
  if (!result) {
    return <></>
  } else {
    return (
      <>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center">
          <Grid item xs={10}>
            <img height="160px" width="160px" src={result.icon} alt={result.title + ' icon'} />
          </Grid>

          <Grid
            item
            xs={11}
            style={{
              marginTop: '30px',
              marginBottom: '30px',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 400,
              color: '#051F2C',
            }}
          >
            {result.title}
          </Grid>

          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            {result.subtitle}
          </Grid>

          <Grid item xs={12}>
            {result.subtitle2}
          </Grid>
        </Grid>
      </>
    )
  }
}
export default ResultModal
