import { useState } from 'react'

import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useMediaQuery, useTheme } from '@mui/material'

import AssuranceLogo from 'images/logos/assurance_logo.svg'
import clsx from 'clsx'
import { isEmpty } from 'lodash'

import DrawerMenu from '../DrawerMenu/DrawerMenu'

import navStyles from './NavigationBar.module.scss'
import styles from '../../styles.module.scss'

const NavigationBar = ({ primaryPerson, topNavs, otherNavs }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const openAnchorEl = Boolean(anchorEl)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    window.location.pathname = '/auth/logout'
  }

  const displayName = () => {
    if (isEmpty(primaryPerson)) {
      return ''
    } else {
      return primaryPerson.first_name + ' ' + (primaryPerson.last_name || '').substring(0, 1) + '.'
    }
  }

  return (
    <header className={clsx(styles.menuBody, styles.nav)}>
      <div className={navStyles.navBar}>
        {isMobile ? <DrawerMenu topNavs={topNavs} otherNavs={otherNavs} /> : ''}
        <img src={AssuranceLogo} alt="Assurance Logo" width="170px" height="26px" />
        <div className="">
          <Button
            id="fade-button"
            className={styles.capitalizeText}
            aria-controls={openAnchorEl ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAnchorEl ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {displayName()}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={openAnchorEl}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  )
}

export default NavigationBar
