import { Box, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import modalStyles from './PlanModal.module.scss'

const PlanModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={modalStyles.modalBox}>
        <div className={modalStyles.modalHeader}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.headerTitle}
          </Typography>
          <IconButton className={modalStyles.close} onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {props.children}
      </Box>
    </Modal>
  )
}

export default PlanModal
