import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import App from './App'
import { theme } from './styles/theme'

import styles from './styles.module.scss'
import './styles/global.scss'

const Portal = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename="/">
        <div className={styles.memberPortalContainer}>
          <App />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Portal
