import { NavLink } from 'react-router-dom'

import { Grid } from '@mui/material'

import clsx from 'clsx'
import { isEmpty } from 'lodash'

import LinkPolicyComponent from '../MedicareApplication/LinkPolicyComponent'
import PolicyCard from '../MemberApplications/PolicyCard'
import { capitalizeFirstLetter } from '../../utils'

import styles from '../../styles.module.scss'
import medAppStyles from '../MedicareApplication/MedicareApplication.module.scss'

const ApplicationStatusComponent = () => {
  return (
    <>
      <div className={medAppStyles.title}>Make the most out of your Medicare Plan</div>
      <div className={medAppStyles.subTitle}>
        Setup a call with an Assurance Medicare Customer support representative who can help you get
        the most out of your Medicare plan.
      </div>

      <NavLink to={'/profile'}>
        <button className={clsx(styles.button, styles.whiteText, medAppStyles.button)}>
          <span>Get Started</span>
        </button>
      </NavLink>
    </>
  )
}

const CARRIER_LOGIN_MAP = {
  wellcare: 'https://member.wellcare.com',
  humana: 'https://www.humana.com/member/manage-your-account',
  cigna: 'https://my.cigna.com/web/public/guest',
  uhc: 'https://www.healthsafe-id.com/rt/login/uhc',
  anthem: 'https://www.anthem.com/login/',
  aetna: 'https://www.aetnamedicare.com/en/login-register.html',
}

const HelpLinksComponent = ({ application }) => {
  const carrier_name = application.attributes.carrier_name || ''
  const carrier_link = CARRIER_LOGIN_MAP[carrier_name.toLocaleLowerCase()] || ''

  if (isEmpty(carrier_link)) {
    return null
  }

  return (
    <>
      <hr className={styles.horizontalBreakLine} />
      <div className={medAppStyles.title}>Helpful Links:</div>
      <div className={medAppStyles.helpLinks}>
        <a href={carrier_link} target="_blank" rel="noopener noreferrer">
          {capitalizeFirstLetter(carrier_name)} Login portal
        </a>
      </div>
    </>
  )
}

const MedicareCompletedApplication = ({ application, progressDetail }) => {
  const policyExist = application.attributes.policy_found === true

  if (isEmpty(application)) {
    return null
  }
  return (
    <Grid className={medAppStyles.wrapper}>
      <PolicyCard application={application} in_progress={false} />
      <hr className={styles.horizontalBreakLine} />
      <ApplicationStatusComponent />
      <HelpLinksComponent application={application} />
      {!policyExist && (
        <>
          <hr className={styles.horizontalBreakLine} />
          <LinkPolicyComponent progressDetail={progressDetail} />
        </>
      )}
    </Grid>
  )
}

export default MedicareCompletedApplication
