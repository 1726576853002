import TelephoneNumber from '../TelephoneNumber/TelephoneNumber'

import styles from '../MedicareApplication/MedicareApplication.module.scss'

const NeedHelpComponent = ({ medicareData }) => {
  return (
    <div className={styles.needHelp}>
      Need help? Speak to a licensed insurance agent at{' '}
      <TelephoneNumber phoneNumber={medicareData.help_phone} />
    </div>
  )
}

export default NeedHelpComponent
