import { useEffect, useState } from 'react'

import { Grid, LinearProgress, makeStyles } from '@mui/material'

import styles from './UploadFileProgressComponent.module.scss'

const UploadFileProgressComponent = ({ file, progressPercentage }) => {
  const [progress, setProgress] = useState(0)
  const useStyles = makeStyles({
    root: {
      height: 15,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: '#DEE5EA',
    },
    bar: {
      borderRadius: 10,
      backgroundColor: '#007CC2',
    },
  })

  const classes = useStyles()
  useEffect(() => {
    setProgress((oldProgress) => {
      if (oldProgress === 100) {
        return 100
      }
      return progressPercentage
    })
  }, [progressPercentage])

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11}>
          <h1 className={styles.fileName}>{file.name}</h1>
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: '100%' }}
            classes={{
              root: classes.root,
              colorPrimary: classes.colorPrimary,
              bar: classes.bar,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default UploadFileProgressComponent
