import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Grid, MenuItem, Select } from '@mui/material'

import UpdateButton from './UpdateButton'
import { put } from '../../utils/requester'

const UpdateMaritalStatus = ({ defaultValue, personId }) => {
  const [maritalStatus, setMaritalStatus] = useState(defaultValue || '')
  const navigate = useNavigate()

  const updateMaritalStatus = () => {
    const body = { marital_status: maritalStatus }
    return put(`/member_portal/api/v1/people/${personId}`, body).then(() => {
      navigate('/profile')
    })
  }

  return (
    <Grid container direction={'column'} spacing={4} justify="center">
      <Grid item>
        <Select
          id="marital-status"
          value={maritalStatus}
          defaultValue={maritalStatus}
          label="Marital Status"
          variant="outlined"
          onChange={(e) => setMaritalStatus(e.target.value)}
          fullWidth
        >
          <MenuItem hidden value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="single">Single</MenuItem>
          <MenuItem value="married">Married</MenuItem>
          <MenuItem value="divorced">Divorced</MenuItem>
          <MenuItem value="separated">Separated</MenuItem>
          <MenuItem value="widowed">Widowed</MenuItem>
        </Select>
      </Grid>
      <UpdateButton handler={updateMaritalStatus} />
    </Grid>
  )
}

export default UpdateMaritalStatus
