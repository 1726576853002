import { Grid } from '@mui/material'

import styles from './ToDoStatusProgress.module.scss'

const ToDoStatusProgress = () => {
  return (
    <Grid>
      <div className={styles.description}>
        The carrier is processing your application. We will notify you here and by email of any next
        steps required
      </div>
    </Grid>
  )
}

export default ToDoStatusProgress
