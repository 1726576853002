import { useState } from 'react'

import PreviousUploadedComponent from './PreviousUploadedComponent/PreviousUploadedComponent'
import ToDoStatusProgress from './ToDoStatusProgress/ToDoStatusProgress'
import UploadComponent from './UploadComponent/UploadComponent'

const ToDoTask = ({ medicareData, initialTaskDetail }) => {
  const [taskDetail, setTaskDetail] = useState(initialTaskDetail)
  const { status } = taskDetail.attributes

  const handleFileUpload = (result) => {
    if (result?.data) {
      setTaskDetail(result?.data)
    }
  }

  if (
    status === 'waiting_document' ||
    status === 'rejected_by_agent' ||
    status === 'rejected_by_carrier'
  ) {
    return <UploadComponent taskDetail={taskDetail} onFileUpload={handleFileUpload} />
  } else if (
    status === 'file_checking' ||
    status === 'bad_file' ||
    status === 'agent_veryfing' ||
    status === 'ready_sent_to_carrier' ||
    status === 'carrier_veryfing'
  ) {
    return (
      <PreviousUploadedComponent
        medicareData={medicareData}
        taskDetail={taskDetail}
        status={status}
      />
    )
  } else if (status === 'accepted' || status === 'unable_to_complete_task') {
    return null
  } else {
    return <ToDoStatusProgress />
  }
}

export default ToDoTask
